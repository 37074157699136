import React, { useCallback } from 'react';
import classes from 'features/Model/Enclosure/UserDefinedCodes/style.module.scss';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import { Field } from 'redux-form';
import { getOptions, getBaseUnits, getUnitOptions } from 'utils/fields';
import { getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import Accordion from 'components/Accordion';
import AccordionActions from "../../AccordionActions";

const overallResistanceValidation = getValidation('udefWindowOverallThermalResistance');
const overallTransmittanceValidation = getValidation('udefWindowOverallThermalResistanceUValue');
const frameHeightValidation = getValidation('udefWindowOverallFrameHeight');
const shgcValidation = getValidation('udefWindowOverallFrameHeight');
const glazingValidation = getValidation('udefWindowOverallGlazingType');
const styleValidation = getValidation('udefWindowOverallStyle');
const fillGasValidation = getValidation('udefWindowOverallFillGas');
const lowECoatingValidation = getValidation('udefWindowOverallLowECoating');


export default React.memo(({
    change,
    accessor,
    overallResistanceId,
    overallResistanceValue,
    overallResistanceUnits,
    frameHeightUnits,
    modelUnits,
    overallLayers,
    id
}) => {

    const deleteLayer = useCallback(() => {
        const { [id]:_, ...remainingLayers } = overallLayers;
        change('uDefCode.layers.window', remainingLayers);
    },[id, overallLayers, change]);
    
    return (
        <div>
            <Accordion
                className={classes.accordion}
                initOpenState={true}
                heading={
                    <span className={classes.accordionHeader}>
                        <span className={classes.accordionTitle}>Overall Characteristics</span>
                    </span>
                }
                hoverActions={<AccordionActions accessor={accessor} deleteLayer={deleteLayer} />}
            >
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.overallThermalResistance`}
                        options={[
                            {
                                label: 'RSI/R-Value',
                                value: {
                                    id: 0,
                                    value: overallResistanceId === 1 ? 1/overallResistanceValue : overallResistanceValue
                                }
                            },
                            {
                                label: 'U-Value',
                                value: {
                                    id: 1,
                                    value: overallResistanceId === 0 ? 1/overallResistanceValue : overallResistanceValue
                                }
                            },
                        ]}
                        label="Overall Thermal Resistance"
                        placeholder="Choose Input Type"
                        validate={overallResistanceValidation}
                        info={'Select input units for overall thermal resistance.'}
                    />
                    {
                        overallResistanceId === 0 &&
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.overallThermalResistance.value`}
                                label="Thermal Resistance Value"
                                placeholder={0.000000}
                                validate={overallResistanceValidation}
                                setTouched={true}
                                decimals={getDecimalPlaces('udefWindowOverallThermalResistance')}
                                change={change}
                                setValue={overallResistanceValue}
                                units={{
                                    base:getBaseUnits('udefWindowOverallThermalResistance', modelUnits),
                                    options:getUnitOptions('thermalResistance'),
                                    selected:overallResistanceUnits,
                                    unitType:'thermalResistance',
                                    accessor:`${accessor}.overallThermalResistance_u`
                                }}
                            />
                    }
                    {
                        overallResistanceId === 1 &&
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.overallThermalResistance.value`}
                                label="Thermal Transmittance Value"
                                placeholder={0.000000}
                                validate={overallTransmittanceValidation}
                                setTouched={true}
                                decimals={getDecimalPlaces('udefWindowOverallThermalResistanceUValue')}
                                change={change}
                                setValue={overallResistanceValue}
                                units={{
                                    base:getBaseUnits('udefWindowOverallThermalResistanceUValue', modelUnits),
                                    options:['W/m²K'],
                                    selected:'W/m²K',
                                    unitType:'thermalTramsmittance',
                                    accessor:`${accessor}.overallThermalResistance_u`
                                }}
                            />
                    }
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.frameHeight`}
                        label="Frame Height"
                        placeholder={0.00}
                        validate={frameHeightValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces('udefWindowOverallFrameHeight')}
                        change={change}
                        units={{
                            base:getBaseUnits('udefWindowOverallFrameHeight', modelUnits),
                            options:getUnitOptions('length'),
                            selected:frameHeightUnits,
                            unitType:'length',
                            accessor:`${accessor}.frameHeight_u`
                        }}
                    />
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.shgc`}
                        label="SHGC"
                        placeholder={0.00}
                        validate={shgcValidation}
                        decimals={getDecimalPlaces('udefWindowOverallSHGC')}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.glazingType`}
                        options={getOptions({fieldKey:'udefWindowOverallGlazingType'})}
                        label="Glazing Type"
                        placeholder="Choose Glazing Type"
                        validate={glazingValidation}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.windowStyle`}
                        options={getOptions({fieldKey:'udefWindowOverallStyle'})}
                        label="Window Type"
                        placeholder="Choose Window Type"
                        validate={styleValidation}
                    />
                </InputRow>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={Select}
                        name={`${accessor}.fillGas`}
                        options={getOptions({fieldKey:'udefWindowOverallFillGas'})}
                        label="Fill Gas"
                        placeholder="Choose Fill Gas"
                        validate={fillGasValidation}
                    />
                    <Field
                        component={Select}
                        name={`${accessor}.lowECoating`}
                        options={getOptions({fieldKey:'udefWindowOverallLowECoating'})}
                        label="Low-E Coating"
                        placeholder="Choose Low-E Coating Type"
                        validate={lowECoatingValidation}
                    />
                </InputRow>
            </Accordion>
        </div>
    )
});