import React from "react";
import Switch from "@material-ui/core/Switch";
import { Redirect } from "react-router";
import Input from "components/Input";
import Loading from "components/Loading";
import Select from "components/Input/Select";

import searchIcon from "assets/images/icons/search.svg";

import classes from "./style.module.scss";

export default React.memo(
    ({
        children,
        tabs = [],
        tabActive,
        onChange,
        type = "",
        className,
        tabNavClassName,
        title,
        withSearch,
        inputClasses,
        searchOnChange,
        searchValue,
        isLoading,
        isNotActiveTabLabel,
        filteredTabs,
        hasOrganization,
        showOrganizationUpgrades,
        setShowOrganizationUpgrades,
        dropdownTitle,
        dropdownOptions,
        dropdownInput,
    }) => {
        if (!isLoading && !tabs.map((tab) => tab.url).includes(tabActive)) {
            return <Redirect to={"/404-page-not-found"} />
        }

        return (
            <div className={`${classes.tabs} ${className ? className : ""}`}>
                <div
                    className={`${classes.tabNav} ${type === "subTabs" ? classes.subTabs : ""} ${
                        tabNavClassName ? tabNavClassName : ""
                    }`}
                >
                    {title && <h3>{title}</h3>}

                    {dropdownTitle && (
                        <div className={inputClasses ? inputClasses : ""}>
                            <Select options={dropdownOptions} input={dropdownInput} />
                        </div>
                    )}
                    {withSearch && (
                        <div className={inputClasses ? inputClasses : ""}>
                            <Input
                                input={{
                                    value: searchValue,
                                    onChange: (e) => searchOnChange(e),
                                }}
                                icon={searchIcon}
                            />
                        </div>
                    )}
                    <ul>
                        {isLoading ? (
                            <div>
                                <Loading />
                            </div>
                        ) : withSearch ? (
                            filteredTabs.map(
                                ({ name, url = "", disabled, icon, className: tabClass, minWidth = "0rem" }, index) => {
                                    const activeTab = url;
                                    const isActive = activeTab === tabActive;

                                    return (
                                        <li
                                            key={index}
                                            disabled={disabled}
                                            className={`${isActive ? classes.active : ""} ${tabClass ? tabClass : ""}`}
                                            onClick={(e) => (!disabled ? onChange(url) : null)}
                                            style={{ minWidth: minWidth }}
                                        >
                                            <span>
                                                {icon && <img src={icon} alt={name} />} {name}
                                            </span>
                                        </li>
                                    );
                                }
                            )
                        ) : (
                            tabs.map(
                                ({ name, url = "", disabled, icon, className: tabClass, minWidth = "0rem" }, index) => {
                                    const activeTab = url;
                                    const isActive = activeTab === tabActive;

                                    return (
                                        <li
                                            key={index}
                                            disabled={disabled}
                                            className={`${isActive ? classes.active : ""} ${tabClass ? tabClass : ""}`}
                                            onClick={(e) => (!disabled ? onChange(url) : null)}
                                            style={{ minWidth: minWidth }}
                                        >
                                            <span>
                                                {icon && <img src={icon} alt={name} />} {name}
                                            </span>
                                        </li>
                                    );
                                }
                            )
                        )}
                    </ul>
                </div>
                {tabActive === "/" && isNotActiveTabLabel ? (
                    <h2 className={classes.isNotActiveTab}>{isNotActiveTabLabel}</h2>
                ) : (
                    <div className={classes.tab}>
                        {React.Children.map(children, (child, index) => {
                            const { url = "" } = tabs[index] || {};

                            const activeTab = url;
                            const isActive = activeTab === tabActive;

                            if (!isActive) {
                                return null;
                            }

                            return child;
                        })}
                    </div>
                )}
            </div>
        )
    }
);
