import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import initCodeLib from "features/CodeLibrary/Templates/initCodeLib.json";
import config from "_firebase/firebaseconfig.json";

const firebaseConfig = {
    apiKey: process.env.NODE_ENV === "development" ? process.env.REACT_APP_FIREBASE_KEY : config.REACT_APP_FIREBASE_KEY,
    authDomain: config.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: config.REACT_APP_FIREBASE_DATABASE,
    projectId: config.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: config.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config.REACT_APP_FIREBASE_SENDER_ID,
    appId: config.REACT_APP_FIREBASE_APP_ID,
    measurementId: config.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const USERS_COLL = "users";
export const MODEL_COLL = "houseModels";
export const SHARED_LIB_COLL = "sharedLibraries";
export const ORG_COLL = "organizations";
export const WINDOW_CODES_COLL = "windowStandardCodes";
export const MODEL_ERRORS_COLL = "houseModelErrors";
export const COMM_COLL = "communities";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const fieldValue = firebase.firestore.FieldValue;
export const storageRef = firebase.storage().ref();
export const storage = firebase.storage;

// Google Sign-In
const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => auth.signInWithRedirect(provider);

// Generate User
export const generateUser = async (user, { modelDir, commDir, ...additionalData }) => {
    if (!user) return;

    const userRef = firestore.doc(`${USERS_COLL}/${user.uid}`);
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
        const { email = "", name = "", uid = "" } = user;
        const createdAt = new Date();

        try {
            await userRef.set({
                uid,
                name,
                email,
                createdAt,
                language: "English",
                units: "Metric",
                nonErsUser: true,
                ...additionalData,
            });

            await userRef.collection("modelDir").doc("directory").set(modelDir);
            await userRef.collection("commDir").doc("directory").set(commDir, {merge:true});
            const codeLibRef = userRef.collection("codeLib");
            Object.entries(initCodeLib).forEach(async ([compType, codes]) => {
                await codeLibRef.doc(compType).set({ codes });
            });
        } catch (error) {
            console.error("Error creating user ", error);
        }
    }
    return getUser(user.uid);
};

export const getUser = async (uid) => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`${USERS_COLL}/${uid}`).get();
        let userData = userDocument.data();

        userData = Object.keys(userData) === "userDetails" ? userData.userDetails : userData;

        return {
            uid,
            ...userData,
        };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};

export const getUserToken = async () => {
    const idToken = await auth.currentUser.getIdToken(true);
    return idToken;
};

export const signInWithToken = async (customToken) => {
    await auth.signInWithCustomToken(customToken).catch(() => {});
};
