export const chbaRoadmapPhases = {
    "1a": {
        homeownerInterest: {
            checkboxValue: false,
            form: "",
            withForm: true,
            withFileUpload: true,
            allowedFileTypes: ["pdf", "jpg", "jpeg", "png"],
            // allowedFileTypes: ["jpg", "jpeg", "png"], // Allowed file types should be in lowercase and without dot
            // minimumFilesRequired: 3, // if zero then no minimum required
            files: [], // just ids because link to images and files will have the same body, "firebase.storage.com/images/id1", "firebase.storage.com/images/id2"
            externalLink: "https://drive.google.com/file/d/1j31OGGNkGXD3K397Ko7FkNnahDWjMgI9/view?usp=drive_link",
            externalLinkTitle: "Homeowner Interest Form",
            // tags: ["REA", "HOM"],
            // downloadLink: {
            //     // url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download, if multiple files, then link to zip
            //     url: "https://drive.google.com/file/d/1mGmuQ9Qf7t3kOK6G9kULxvu6Ky8C2n6I/view?usp=drive_link", //link to direct download, if multiple files, then link to zip
            //     fileName: "Placeholder-PDF.pdf", // needs to have an extension, if multiple files, then extension should be .zip
            // },
            // downloadLink needs to be an array now
            // filesToDownload: [], // we need to think of case with multiple files, so we won't need to add multiple buttons
        },
        preEnrollment: {
            checkboxValue: false,
            form: "",
            withForm: true,
            // tags: ["MUN", "SO", "REA", "RENO", "HOM"],
            // withForm: false,
            // withFileUpload: true,
            // allowedFileTypes: ["pdf"], //".pdf", ".doc", ".docx", "xls", "xlsx",
            // minimumFilesRequired: 2, // if zero then no minimum required
            // files: [], // just ids because link to images and files will have the same body, "firebase.storage.com/images/id1", "firebase.storage.com/images/id2"
            // externalLink:
            //     "https://drive.google.com/file/d/1CjvaCdxlR9WD_ixc5XGZkaSib2SV2cqO/view?usp=drive_link",
            // filesToDownload: [], // we need to think of case with multiple files, so we won't need to add multiple buttons
        },
        connectEA: {
            checkboxValue: true,
            form: "",
            withForm: true,
        },
    },
    "1b": {
        projectScopeIntro: {
            checkboxValue: false,
            withForm: true,
            form: "",
            // downloadLink: {
            //     url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //     fileName: "Placeholder-PDF.pdf", // needs to have an extension
            // },
            externalLink: "https://drive.google.com/file/d/1CjvaCdxlR9WD_ixc5XGZkaSib2SV2cqO/view?usp=drive_link",
            externalLinkTitle: "Towards NZ Renos Overview",
        },
        idealCandidateSite: {
            checkboxValue: false,
            externalLink: "https://drive.google.com/file/d/1Ycr5xSoKVVaeQFUDiUaNrdptMegFEOR2/view?usp=drive_link",
            externalLinkTitle: "Ideal Candidate Checklist",
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            form: "",
            withForm: true,
            // viewFile: [
            //     {
            //         url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //         fileName: "Placeholder-PDF.pdf", // needs to have an extension
            //     },
            //     {
            //         url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //         fileName: "Placeholder-PDF.pdf", // needs to have an extension
            //     },
            //     {
            //         url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //         fileName: "Placeholder-PDF.pdf", // needs to have an extension
            //     },
            //     {
            //         url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //         fileName: "Placeholder-PDF.pdf", // needs to have an extension
            //     },
            // ],
        },
    },
    "1c": {
        nzqueaAssigned: {
            checkboxValue: false,
            form: "",
            withForm: true,
        },
    },
    2: {
        dataCollectionReport: {
            checkboxValue: false,
            externalLink: "https://drive.google.com/file/d/1AeShcZcPu2p-BxesWzDbVwE9wIqMOYGN/view?usp=drive_link",
            externalLinkTitle: "Photo Guidance Document",
            form: "",
            withForm: true,
            // viewFile: [
            //     {
            //         url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //         fileName: "Placeholder-PDF.pdf", // needs to have an extension
            //     },
            // ],
        },
        preRenoEnergyUsage: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Comments on utility bill data, if applicable",
            // withForm: true,
            // formTitle: "Comments on utility bill data, if applicable",
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            // table goes here
        },
        preRetrofitTestingInspection: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf", "jpg", "jpeg", "png"],
            form: "",
            withForm: true,
        },
        radonTest: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            formTitle: "Indicate reason test not completed, if applicable",
            form: "",
            withForm: true,
        },
        identifyKeyOpportunitiesChallenges: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            externalLink: "https://drive.google.com/file/d/1il7yjNFXUAMKBVfUaXxh_aFp-qTLvveF/view?usp=drive_link",
            externalLinkTitle: "Tier 2 Candidate Assessment",
            form: "",
            withForm: true,
        },
        homeAppraisal: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            form: "",
            withForm: true,
        },
    },
    3: {
        reviewOptimizationOptions: {
            checkboxValue: false,
            externalLink: "https://drive.google.com/file/d/16rDGweiYLz5fAuBcMO_txusr62dq77Mq/view?usp=drive_link",
            externalLinkTitle: "Optimization & Benchmarking Options & Solutions",
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            form: "",
            withForm: true,
            formTitle: "Comments on optimization plan, if applicable",
        },
        initialPathOptimizationReport: {
            checkboxValue: false,
            externalLink: "https://drive.google.com/file/d/17NQZpa3Xio8sj9EqSLnza2q7TCjCoTjn/view?usp=drive_link",
            externalLinkTitle: "Sample Path to NZ Report",
            form: "",
            withForm: true,
            formTitle: "Comments on initial optimization process, if applicable",
            // withFileUpload: true,
            // allowedFileTypes: ["pdf"],
            // downloadLink: {
            //     url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //     fileName: "Placeholder-PDF.pdf", // needs to have an extension
            // },
        },
        proposedRenewableSolutionPlan: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            form: "",
            withForm: true,
            formTitle: "Comments on renewable energy plan, if applicable",
        },
        heatLossGainRoombyRoom: {
            checkboxValue: false,
            withForm: true,
            form: "",
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
        },
        nrcanHeatPumpSizing: {
            checkboxValue: false,
            form: "",
            withForm: true,
            withFileUpload: true,
            allowedFileTypes: ["pdf", "xlsm", "xlsx", "xls"],
            formTitle: "Comments on results, if applicable",
            externalLink:
                "https://natural-resources.canada.ca/maps-tools-and-publications/tools/modelling-tools/toolkit-for-air-source-heat-pump-sizing-and-selection/23558",
            externalLinkTitle: "ASHP Sizing Tool",
        },
        materialCarbonEmissionsEstimator: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Comments on results, if applicable",
            withFileUpload: true,
            allowedFileTypes: ["pdf", "xlsm", "xlsx", "xls"],
            externalLink: "https://drive.google.com/file/d/1VyV_CD9l0YiQ29UdqHeSnoyKRXzESvC4/view?usp=drive_link",
            externalLinkTitle: "MCE2 Download",
        },
        costBenefitAnalysis: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Comments on results, if applicable",
            optional: true,
        },
    },
    4: {
        idpChecklist: {
            checkboxValue: false,
            externalLink:
                "https://docs.google.com/document/d/12tUrwrPgcTlMwDd83Ym2HR__OaaeqsKG/edit?usp=drive_link&ouid=105399927586561832953&rtpof=true&sd=true",
            externalLinkTitle: "IDP Checklist",
            form: "",
            withForm: true,
            // viewFile: [
            //     {
            //         url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //         fileName: "IDP-suggestion-checklist.pdf", // needs to have an extension
            //     },
            // ],
        },
        initalPathNzOptimization: {
            checkboxValue: false,
            withform: true,
            form: "",
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
        },
        createEstConstructionTimeline: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Timeline description",
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
        },
        prepareNextStepsIdpNotes: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Comments on IDP process and next steps",
            // viewFile: [
            //     {
            //         url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //         fileName: "IDP-suggestion-checklist.pdf", // needs to have an extension
            //     },
            // ],
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            externalLink:
                "https://docs.google.com/document/d/1ju09VU4kWZ_owo4BAAW5TVSYvgWSffrm/edit?usp=drive_link&ouid=105399927586561832953&rtpof=true&sd=true",
            externalLinkTitle: "Sample IDP Agenda",
        },
        finalizePathToNzeroSpecs: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            form: "",
            withForm: true,
            formTitle: "Comments on final optimization process, if applicable",
            // viewFile: [
            //     {
            //         url: "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf", //link to direct download
            //         fileName: "IDP-suggestion-checklist.pdf", // needs to have an extension
            //     },
            // ],
        },
    },
    5: {
        consentDesignPackage: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Additional comments, if applicable",
        },
        constructionCosts: {
            checkboxValue: false,
            form: "",
            withForm: true,
            withCostInput: true,
            formTitle: "Additional comments, if applicable",
        },
    },
    6: {
        midConstructionBlowerTest: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf", "jpg", "jpeg", "png"],
            externalLink: "https://drive.google.com/file/d/1AeShcZcPu2p-BxesWzDbVwE9wIqMOYGN/view?usp=drive_link",
            externalLinkTitle: "Photo Guidance Document",
            form: "",
            withForm: true,
        },
        properDetailImplementation: {
            checkboxValue: false,
            withForm: true,
            form: "",
            formTitle: "Describe",
            // withFileUpload: true,
            // allowedFileTypes: ["pdf"],
        },
    },
    7: {
        ersFinalInspectionTestingProtocol: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf", "jpg", "jpeg", "png"],
            form: "",
            withForm: true,
        },
        additionalNzeroRenoInspectionProtocol: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            form: "",
            withForm: true,
        },
        postCaseStudyPhotos: {
            checkboxValue: false,
            form: "",
            withForm: true,
            withFileUpload: true,
            allowedFileTypes: ["jpg", "jpeg", "png"],
        },
    },
    8: {
        ersFinalLabelSubmission: {
            checkboxValue: false,
            form: "",
            withForm: true,
        },
        chbaNzeroFinalProjectSubmission: {
            checkboxValue: false,
            form: "",
            withForm: true,
        },
    },
    9: {
        debriefDiscussionWithClientTeam: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Comments lessons learned (for case study)",
            // withFileUpload: true,
            // allowedFileTypes: ["pdf"],
        },
        updatedFinalPathNzeroRenoOptimization: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Comments final as-built package, if applicable",
            // withFileUpload: true,
            // allowedFileTypes: ["pdf"],
        },
        programLabelsRecognition: {
            checkboxValue: false,
            form: "",
            withForm: true,
        },
        confirmIncentiveApplicationsTimelines: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Additional comments",
        },
    },
    10: {
        developCaseStudy: {
            checkboxValue: false,
            form: "",
            withForm: true,
            formTitle: "Additional information for use in the case study (paragraph or bullets)",
            // withFileUpload: true,
            // allowedFileTypes: ["pdf"],
        },
        submitInfoToSharedFolders: {
            checkboxValue: false,
            form: "",
            withForm: true,
        },
    },
    11: {
        postRetrofitRadonResults: {
            checkboxValue: false,
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            form: "",
            withForm: true,
        },
    },
    12: {
        homeAppraisal: {
            checkboxValue: false,
            withForm: true,
            formTitle: "Indicate reason not completed, if applicable.",
            form: "",
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
        },
    },
    13: {
        postRetrofitUtilityConsumptionCost: {
            checkboxValue: false,
            form: "",
            withForm: true,
            withFileUpload: true,
            allowedFileTypes: ["pdf"],
            // externalLink:
            //     "https://www.chba.ca/CHBA/Housing_in_Canada/Net_Zero_Energy_Program/Net_Zero_Renovations.aspx",
        },
    },
};

export const phaseTitles = [
    "1.a. Net Zero Renovation Project Enrollment",
    "1.b. Initial Client Meeting",
    "1.c. Project Introduction",
    "2. Discovery",
    "3. Benchmarking & Optimization",
    "4. Integrated Design Charette",
    "5. Design Sign-off & Contracts",
    "6. Construction",
    "7. Final Inspection & Testing",
    "8. File Development & Submissions",
    "9. Client Debrief & Label Presentation",
    "10. Project Case Study Development",
];

export const followUpPhaseTitles = ["11. Radon Testing", "12. Appraisal", "13. Post-construction Utility Bills"];

export const checkboxTitles = [
    // 1a
    {
        homeownerInterest: { title: "Homeowner interest signature form", subtitle: "" },
        preEnrollment: { title: "Project enrollment form completed on CHBA website", subtitle: "" },
        connectEA: { title: "EA provided with Volta SNAP access", subtitle: "" },
    },
    //1b
    {
        projectScopeIntro: { title: "Initial introduction to national project scope", subtitle: "" },
        idealCandidateSite: { title: "Candidate, site, PV, and project assessment", subtitle: "" },
    },
    //1c
    {
        nzqueaAssigned: {
            title: "Follow up and introduction from NZ EA project manager",
            subtitle: "Verify that a NZ EA is assigned to the project",
        },
    },
    // 2
    {
        dataCollectionReport: {
            title: "Data collection report & outline of IDP agenda",
            subtitle: "Review linked photo guidance document",
        },
        preRenoEnergyUsage: {
            title: "Collect 1 year of pre-renovation energy usage (utility) data",
            subtitle: "Input usage and costs in the Review Tab. Optionally, upload utility bills below.",
        },
        preRetrofitTestingInspection: {
            title: "Pre-retrofit EnerGuide Rating System testing & inspection",
            subtitle: "H2k file uploads coming soon!",
        },
        radonTest: { title: "Schedule & coordinate pre-construction radon test", subtitle: "(CARST Certified)" },
        identifyKeyOpportunitiesChallenges: {
            title: "Identify key challenges & opportunities for enclosure, HVAC, and renewable upgrades and homeowner comfort",
            subtitle: "Download, complete, and submit Tier 2 Candidate Assessment",
        },
        homeAppraisal: {
            title: "Schedule & coordinate pre-construction home appraisal",
            subtitle: "(Appraisal Institue of Canada)",
        },
    },
    // 3
    {
        reviewOptimizationOptions: {
            title: "Review optimization options & solutions",
            subtitle: "Upload air barrier details, plan for enclosure, etc. if appropriate",
        },
        initialPathOptimizationReport: {
            title: 'Develop initial "Path to Net Zero" optimization report',
            subtitle: "Navigate to the Upgrades tab to create your optimization report",
        },
        proposedRenewableSolutionPlan: { title: "Develop proposed renewable solution & plan", subtitle: "" },
        heatLossGainRoombyRoom: { title: "CSA F280-12 heat loss/heat gain room-by-room calculations", subtitle: "" },
        nrcanHeatPumpSizing: {
            title: "Use NRCan's toolkit for air source heat pump sizing and selection",
            subtitle: "",
        },
        materialCarbonEmissionsEstimator: {
            title: "Material Carbon Emissions Estimator (MCE2) analysis",
            subtitle: "Upload results PDF report",
        },
        costBenefitAnalysis: { title: "Cost Benefit Analysis Tool (CBAT)", subtitle: "(Optional)" },
    },
    // 4
    {
        idpChecklist: { title: "Review IDP checklist", subtitle: "" },
        initalPathNzOptimization: {
            title: 'Present initial "Path to Net Zero" optimization to homeowner',
            subtitle: "",
        },
        createEstConstructionTimeline: {
            title: "Create estimated construction timeline",
            subtitle: "Upload documentation or provide description",
        },
        prepareNextStepsIdpNotes: {
            title: "IDP agenda & attendee identification, next steps/tasks, and IDP notes provided to project team",
            subtitle: "",
        },
        finalizePathToNzeroSpecs: {
            title: 'Finalize "Path to Net Zero" specifications, targets, and technologies',
            subtitle: "Upload final plans if applicable",
        },
    },
    // 5
    {
        consentDesignPackage: { title: "Acquired homeowner consent on final design package", subtitle: "" },
        constructionCosts: { title: "Estimated contract cost", subtitle: "" },
    },
    // 6
    {
        midConstructionBlowerTest: {
            title: "Mid-construction blower door test & documentation",
            subtitle: "Review photo guidance document (linked), and upload test results and photos",
        },
        properDetailImplementation: {
            title: "Air leakage location inspection to confirm proper detail implementation",
            subtitle: "",
        },
    },
    // 7
    {
        ersFinalInspectionTestingProtocol: {
            title: "ERS as-built final inspection & testing protocol & Upload Documentation",
            subtitle: "H2k file uploads coming soon!",
        },
        additionalNzeroRenoInspectionProtocol: {
            title: "Additional NZ Renovation inspection Protocol & upload documentation",
            subtitle: "",
        },
        postCaseStudyPhotos: { title: "Post-retrofit case study photos", subtitle: "" },
    },
    // 8
    {
        ersFinalLabelSubmission: { title: "Confirm ERS as-built final label submission to NRCan", subtitle: "" },
        chbaNzeroFinalProjectSubmission: {
            title: "Confirm CHBA Net Zero Renovation final project submissions",
            subtitle: "",
        },
    },
    // 9
    {
        debriefDiscussionWithClientTeam: {
            title: "Debrief discussions with client & team",
            subtitle: "Includes lessons learned",
        },
        updatedFinalPathNzeroRenoOptimization: {
            title: 'Provide final "Path to Net Zero" optimization report',
            subtitle: "",
        },
        programLabelsRecognition: {
            title: "Provide program labels / recognition",
            subtitle: "Confirm labels were received/delivered to SO",
        },
        confirmIncentiveApplicationsTimelines: {
            title: "Discussions of incentive applications and next steps with homeowner",
            subtitle: "Includes description of timelines, expectations etc.",
        },
    },
    // 10
    {
        developCaseStudy: { title: "Develop case study", subtitle: "Coming soon!" },
        submitInfoToSharedFolders: { title: "Confirm all project documentation submitted", subtitle: "" },
    },
    // 11
    { postRetrofitRadonResults: { title: "Upload 1 year of post-retrofit radon results", subtitle: "" } },
    // 12
    {
        homeAppraisal: {
            title: "Schedule & coordinate post-construction home appraisal",
            subtitle: "(Appraisal Institue of Canada)",
        },
    },
    // 13
    {
        postRetrofitUtilityConsumptionCost: {
            title: "Collect 1 year of post-renovation energy usage data",
            subtitle: "Input usage and costs in the Review Tab. Optionally, upload utility bills below.",
        },
    },
];

// export const getPhaseIndex = (title) => {
//     const id = title.match(/^[1-9]+[.]?[a-z]?/g)[0];
//     switch (id) {
//         case "1a": case "1.a":
//             return 1;
//         case "1b": case "1.b":
//             return 2;
//         case "1c": case "1.c":
//             return 3;
//         default:
//             return parseInt(id) + 2;
//     }
// }

export const totalPhases = Object.keys(chbaRoadmapPhases).length;
