
import React, { useEffect, useState } from "react";
import classes from "../style.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MultiSelect from "components/Input/MultiSelect";
import Select from "components/Input/Select";
import Button from "components/Button";
import FilterIcon from "assets/images/icons/Filter.svg";
import ProgressBar from "components/ProgressBar";
import BarGraph from "components/BarChart";
import { getProvName } from "utils/weather";
import { isEmpty } from "lodash";
import { selectCommunityId } from "../_ducks/selectors";
import { actions as communityActions } from "features/Community/_ducks";
import { getNetTotalEnergyConsumption } from "utils/results";
import { calcOperatingCosts } from "utils/upgrades";
import Loading from "components/Loading";

import {
    Table,
    TableRow,
    TablePagination,
    TableFooter,
} from "@material-ui/core";

const { fetchCommModelData } = communityActions;

const Results = ({
    communityId,
    commModelDir: { models },
    fetchCommModelData,
    defaultRowsPerPage = 5,
    rowsPerPageOptions = [5, 10, 25, 50],
}) => {

    const [loading, setLoading] = useState(true);
    
    // Store all model results and filtered results
    const [allModels, setAllModels] = useState([]);
    const [noResults, setNoResults] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);
    
    // Percentage of models with valid results to display 
    const [progress, setProgress] = useState(0);
    
    // Flag to determine if filters were updated
    const [newFilter, setNewFilter] = useState(false);

    // Selected metric for graphs: energy, opEmissions, opCost, renoCost
    const [graphMetric, setGraphMetric] = useState("energy");

    // Options for filters
    const [locationOpts, setLocationOpts] = useState([]);
    const [userOpts, setUserOpts] = useState([]);
    const [modelOpts, setModelOpts] = useState([]);

    // List of selected filters
    const [selectedLocs, setSelectedLocs] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);

    // Fetch and store results for each model on first render of page
    useEffect(() => {
        if (communityId) {
            const modelIds = Object.keys(models); //.slice(0, 10);
            
            let allResults = [];
            let emptyResults = [];
            let resultsPromise = null;

            for (let i = 0; i < modelIds.length; i++) {
                let modelId = modelIds[i];
                resultsPromise = fetchCommModelData(communityId, modelId).then((data) => {
                    let results = getResults(modelId, data);
                    if (results)
                        allResults.push(results);
                    else {
                        emptyResults.push(models[modelId]);
                    }
                    return allResults;
                });
            };

            // Gets final results list and initialize model lists and options
            resultsPromise.then((allResults) => {
                // Sort alphabetically
                allResults.sort((a, b) => a.name.localeCompare(b.name));
                emptyResults.sort((a, b) => a.name.localeCompare(b.name));
                setAllModels(allResults);
                setNoResults(emptyResults);
                setFilteredModels(allResults);
                fetchFilterOptions(allResults);
                setProgress(allResults.length / modelIds.length * 100);
                setLoading(false);
            });
        }    
    }, [communityId]);

    // Alphabetical sort function for label
    const orderByLabel = (a, b) => a.label.localeCompare(b.label);

    const fetchFilterOptions = (allModels) => {
        let locations = [];
        let users = [];
        let models = [];

        allModels.forEach((model) => {
            if (!locations.includes(model.provTerr))
                locations.push(model.provTerr);

            if (!users.includes(model.userName))
                users.push(model.userName);
            
            models.push(model);
        });
    
        setLocationOpts(locations.map((id) => ({
            value: id,
            label: getProvName(id).toUpperCase(),
        })).sort(orderByLabel));

        setUserOpts(users.map((fullName, index) => ({
            value: `User${index}`,  // user array index
            label: fullName,        // full user name
        })).sort(orderByLabel));
    
        setModelOpts(models.map((model) => ({
            value: model.key,       // model ID
            label: model.name,      // model name
        })));
    };

    // Determine whether model has a value to display in graph
    const hasMetricValue = (model, metric) => {
        return (
            (metric === "opCost") 
                ? model.maxValues.opCost > 0 
                : (metric === "renoCost") 
                    ? model.renoCost !== null 
                    : ["energy", "opEmissions"].includes(metric)
        );
    }

    // Clear filters
    const handleClear = () => {
        const unFilteredData = allModels.filter((model) => hasMetricValue(model, graphMetric));

        // Remove selected filters
        setSelectedLocs([]);
        setSelectedUsers([]);
        setSelectedModels([]);
        
        // Reset filtered models list and disable apply button
        setFilteredModels(unFilteredData);
        setNewFilter(false);
        setPage(0);
    }

    // Apply filters
    const handleApply = (metric) => {
        const filteredData = allModels.filter((model) => {
            const selectedUserNames = userOpts.filter((opt) => selectedUsers.includes(opt.value)).map((opt) => opt.label);
            const matchLocation = isEmpty(selectedLocs) || selectedLocs.includes(model.provTerr);
            const matchUser = isEmpty(selectedUsers) || selectedUserNames.includes(model.userName);
            const matchModel = isEmpty(selectedModels) || selectedModels.includes(model.key);
            
            return matchLocation && matchUser && matchModel && hasMetricValue(model, metric);
        });

        // Update filtered models list and disable button after applying
        setFilteredModels(filteredData);
        setNewFilter(false);
        setPage(0);
    }

    // Update selected filter lists
    const handleChange = (type, value) => {
        if (type === "location")
            setSelectedLocs(value);
        else if (type === "user")
            setSelectedUsers(value);
        else if (type === "model")
            setSelectedModels(value);

        setNewFilter(true);
    }

    // Get upgrade packages data from model
    const grabPackage = (id, result, upgrade, fuelPrices, isBase = false) => {
        let packageResult = isBase ? result.baseCase : result.allUpgrades;
        let { annualEnergyConsumption } = getNetTotalEnergyConsumption(packageResult);
        let { annualEmissions } = packageResult.resultsSummary;
        let operatingCost = (fuelPrices && !isEmpty(fuelPrices)) ? calcOperatingCosts(result.allUpgrades, fuelPrices, "total") : null;

        let packageData = {
            id: isBase ? "base": id,
            name: isBase ? "Base Case" : upgrade.name,
            displayOrder: isBase ? 0 : upgrade.ui?.displayOrder,
            energyConsumption: annualEnergyConsumption,
            opEmissions: annualEmissions,
            opCost: operatingCost,
        };

        return ({
            annualEnergyConsumption, 
            annualEmissions,
            operatingCost,
            packageData
        });
    };
    
    // Extract necessary information from a particular model's data to populate its graph
    const getResults = (key, { details: { fuelPrices = {}, totalRenovationCost = null }, upgrades, results }) => {        
        var allEnergies = [];
        var allEmissions = [];
        var allOpCost = [];
        var data = [];

        // Collect ids for packages with results
        const packageIds = Object.keys(results)
            .filter((id) => !isEmpty(results[id].allUpgrades));
        
        if (isEmpty(packageIds)) return null;

        packageIds.forEach((id, index) => {
            // Add base case on the first run
            if (index === 0) {
                let {
                    annualEnergyConsumption, 
                    annualEmissions,
                    operatingCost,
                    packageData
                } = grabPackage("base", results[id], upgrades[id], fuelPrices, true);

                allEnergies.push(annualEnergyConsumption);
                allEmissions.push(annualEmissions);
                allOpCost.push(operatingCost);
                data.push(packageData);
            }

            // Add upgrade package
            let {
                annualEnergyConsumption, 
                annualEmissions,
                operatingCost,
                packageData
            } = grabPackage(id, results[id], upgrades[id], fuelPrices);

            allEnergies.push(annualEnergyConsumption);
            allEmissions.push(annualEmissions);
            allOpCost.push(operatingCost);
            data.push(packageData);
        });
        
        return ({
            key: key,
            name: models[key].name,
            provTerr: models[key].provTerr,
            userName: models[key].userName,
            data: data,
            renoCost: totalRenovationCost,
            maxValues: {
                energy: Math.max(...allEnergies),
                opEmissions: Math.max(...allEmissions),
                opCost: Math.max(...allOpCost),
            }
        });
    };

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Graphs to display on the current page
    const paginatedRows = rowsPerPage > 0
        ? filteredModels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : filteredModels;

    // The maximum values for each metric among the current page's results
    const maxValues = {
        energy: Math.max(...paginatedRows.map((model) => model.maxValues.energy)),
        opEmissions: Math.max(...paginatedRows.map((model) => model.maxValues.opEmissions)), 
        opCost: Math.max(...paginatedRows.map((model) => model.maxValues.opCost)),
        renoCost: Math.max(...filteredModels.map((model) => model.renoCost)),
    };

    return (
        <div className={classes.contentContainer}>
            <div className={classes.header}>

                <div className={classes.filters}>
                    <img src={FilterIcon} alt="filter" />
                    <MultiSelect
                        hideLabel
                        dropDownWidth="max-content"
                        className={classes.test}
                        forcedLabel="Location"
                        options={locationOpts}
                        input={{
                            value: selectedLocs,
                            onChange: (value) => handleChange("location", value),
                        }}
                    />
                    <MultiSelect
                        hideLabel
                        dropDownWidth="max-content"
                        className={classes.test}
                        forcedLabel="User"
                        options={userOpts}
                        input={{
                            value: selectedUsers,
                            onChange: (value) => handleChange("user", value),
                        }}
                    />
                    <MultiSelect
                        hideLabel
                        dropDownWidth="max-content"
                        className={classes.test}
                        forcedLabel="Models"
                        options={modelOpts}
                        input={{
                            value: selectedModels,
                            onChange: (value) => handleChange("model", value),
                        }}
                    />
                    <Button small disabled={!newFilter} onClick={() => handleApply(graphMetric)}> 
                        Apply 
                    </Button>
                    <Button small type="white" onClick={handleClear}>
                        Clear
                    </Button>
                </div>

                <ProgressBar loading={loading} progress={progress} breakdown={noResults} className={classes.progressBar} />

            </div>
            
            <Select 
                hideLabel
                disabled={loading}
                className={classes.graphTitle}
                name={"metricShown"}
                label={"Metric"}
                options={[
                    {
                        label: "Energy Consumption (GJ)",
                        value: "energy"
                    },
                    {
                        label: "Operational Emissions (t/yr)",
                        value: "opEmissions"
                    },
                    {
                        label: "Operational Cost ($/yr)",
                        value: "opCost"
                    },
                    {
                        label: "Renovation Cost ($)",
                        value: "renoCost"
                    }
                ]}
                setValue={graphMetric}
                input={{
                    value: graphMetric,
                    onChange: (value) => {
                        setGraphMetric(value);
                        handleApply(value);
                    },
                }}
            />

            {loading && <Loading className={classes.spinner} />}
            {!loading && filteredModels.length === 0 && (
                <p className={classes.noResultsText}> There are no results available for the models selected. </p>
            )}
            {!loading && filteredModels.length > 0 && (
                graphMetric === "renoCost" ? ( 
                    <BarGraph
                        allModeldata={filteredModels}
                        metric={graphMetric}
                        maxValues={maxValues}
                    />
                )
                : 
                paginatedRows.map((model) => (
                    <BarGraph
                        key={model.key}
                        singleModelData={model}
                        metric={graphMetric}
                        maxValues={maxValues}
                    />
                ))
            )}
            {(graphMetric !== "renoCost") && (filteredModels.length > rowsPerPage) && (
                <Table>
                    <TableFooter style={{backgroundColor: "white"}}>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                count={filteredModels.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            )}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    communityId: selectCommunityId,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    fetchCommModelData: async (communityId, modelId) => {
        if (communityId && modelId) {
            return dispatch(fetchCommModelData(communityId, modelId));
        }
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);