import React, { useState, useEffect } from "react";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import classes from "./style.module.scss";
import Button from "components/Button";
import InputRow from "components/Input/Row";
import { exportH2kFile, validateH2kExport, exportH2kFileWithResults } from "utils/h2kinout/api";
import { getValidation } from "utils/fieldValidation";
import { Field } from "redux-form";
import Select from "components/Input/Select";
import SummaryTable from "./SummaryTable/container";
import ErrorList from "./ErrorList/container";
import Details from "./Details";
import TabHeader from "components/TabHeader";
import DetailsDrawer from "components/DetailsDrawer";
import ClimateIcon from "assets/images/icons/Climate.svg";
import RatioIcon from "assets/images/icons/Ratio.svg";
import HeatedDaysIcon from "assets/images/icons/HeatedDays.svg";
import Export from "assets/images/icons/JSX/Export";
import Results from "./Results/container";
import Information from "./Information/container";
import F280Summary from "./F280Summary/container";
import EvaluationProgram from "./EvaluationProgram/container";
import NetZeroWorkbook from "./NetZeroWorkbook/container.js";
import Checkbox from "components/Input/Checkbox";
import SettingsIcon from "assets/images/icons/JSX/Settings";

import Tabs from "components/Tabs";
import { mixpanel } from "components/Mixpanel";
import Dialog from "components/Dialog";
import DcfExport from "./DcfExport/container";
import { getProgramType } from "utils/evaluationProgram";
import UtilityData from "./UtiltyData/container";
import Tooltip from "components/Tooltip";

const programClassValidation = getValidation("programClass");

const download = (filename, text) => {
    const element = document.createElement("a");

    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

// const Stats = ({ heatingDegreeDays, windowWallRatio = 0 }) => (
//     <div className={classes.stats}>
//         <div className={classes.stat}>
//             <img src={ClimateIcon} alt="Sun" />
//             <div>
//                 <span className={classes.name}>Climate Zone</span>
//                 <span className={classes.value}>{heatingDegreeDays < 5000 ? "1" : "2"}</span>
//             </div>
//         </div>
//         <div className={classes.stat}>
//             <img src={HeatedDaysIcon} alt="Thermometer and up arrow" />
//             <div>
//                 <span className={classes.name}>Heating Degree Days</span>
//                 <span className={classes.value}>{heatingDegreeDays}</span>
//             </div>
//         </div>
//         <div className={classes.stat}>
//             <img src={RatioIcon} alt="Thermometer and sun" />
//             <div>
//                 <span className={classes.name}>Window to Wall Ratio</span>
//                 <span className={classes.value}>
//                     {Number.isNaN(windowWallRatio) ? (0.0).toFixed(2) : windowWallRatio.toFixed(2)}%
//                 </span>
//             </div>
//         </div>
//     </div>
// );

const Program = ({ programLabel, programType, provTerrId, h2kVersion, change, nonErsUser }) => (
    <div className={sharedClasses.section}>
        <InputRow gridTemplate="1fr 1fr 1fr">
            <Field
                component={Select}
                name="modelData.program.class"
                options={[
                    {
                        value: "",
                        label: "General",
                    },
                    {
                        value: "ca.nrcan.gc.OEE.ERS.ErsProgram",
                        label: "EnerGuide Rating System",
                    },
                    ...(!["v11-9", "v11-10", "v11-11"].includes(h2kVersion)
                        ? [
                              {
                                  value: "ca.nrcan.gc.OEE.ERS2020NBC.ErsProgram",
                                  label: "EnerGuide Rating System 2020 NBC",
                              },
                          ]
                        : []),
                    {
                        value: "ca.nrcan.gc.OEE.ONrh.OnProgram",
                        label: "Ontario Reference House",
                    },
                ]}
                label="Program Type"
                placeholder="None"
                validate={programClassValidation}
                onChange={(value) => {
                    const programType = getProgramType(value);

                    change("modelData.program.type", programType);
                }}
                info={"Note that switching the program type will not reset the fields in this section."}
                warning={
                    provTerrId !== 5 && (programType || "").includes("ONrh")
                        ? "orhModeLocationMismatch"
                        : nonErsUser && programType !== ""
                        ? "nonErsUserProgramSelection"
                        : ""
                }
            />
        </InputRow>
        <EvaluationProgram accessor={"modelData.program"} change={change} programLabel={programLabel} />
    </div>
);

const errorLocationText = {
    export: "Proceed and Export",
    simulation: "Proceed with Simulation",
};

export default ({
    modelId,
    modelName,
    readOnly,
    modelSave,
    saveDrawingData,
    isInvalid,
    h2kVersion,
    exportErrors,
    setExportErrors,
    change,
    heatingDegreeDays,
    windowWallRatio,
    history,
    programType,
    weatherProvTerrId,
    errorLocation,
    errorProceedFunc,
    packages,
    CHBA_NZEA,
    userId,
    nonErsUser,
}) => {
    const initialTab = window.location.search;
    const [activeTab, changeActiveTab] = useState(initialTab);
    const [open, toggleOpen] = useState(false);
    const [exporting, toggleExporting] = useState(false);
    const [exportingDcf, toggleDcfExporting] = useState(false);
    const [dcfErrorMsg, toggledcfErrorMsg] = useState("");
    const [upgradeSelected, toggleUpgradeSelected] = useState("");
    const [applyUpgradesToBase, toggleApplyUpgradesToBase] = useState(false);
    const [exportWithResults, toggleExportWithResults] = useState(false);

    const initialWorkbookSubtab = initialTab.includes("?tab=workbook&subtab=")
        ? initialTab.split("?tab=workbook&subtab=")[1]
        : "general";

    //Reset when coming back to the tab
    useEffect(() => {
        toggledcfErrorMsg("");
        setExportErrors([]);
    }, []);

    //let errorList = [];
    let h2kData = "";

    const upgradeExportOpts = Object.keys(packages)
        .filter((pkg) => pkg !== "base")
        .map((pkg) => ({
            value: pkg,
            label: packages[pkg].name,
        }));

    const actionExport = async () => {
        let returnPackage = {};

        if (exportWithResults) {
            returnPackage = await exportH2kFileWithResults({
                modelId,
                userId,
                packages: upgradeSelected,
                applyUpgradesToBase,
            });

            const { xmlString = "" } = returnPackage || {};
            try {
                if (xmlString !== "") {
                    const { label: selectedLabel = "" } =
                        upgradeExportOpts.find(({ value }) => value === upgradeSelected) || {}; //adding upgrade name to file name
                    h2kData = xmlString;

                    if (h2kData.charAt(0) === "\r" || h2kData.charAt(0) === "\n") {
                        h2kData = h2kData.substring(1);
                    }

                    //Handle RURComments new line characters
                    // if (h2kData.includes("<RURComments")) {
                    //     h2kData = h2kData.replace("\r\n", "&#x0D;&#x0A;");
                    // }

                    const downloadName = selectedLabel
                        ? `${modelName}-${selectedLabel}`.replace(/\./g, " ")
                        : `${modelName}`.replace(/\./g, " ");

                    download(`${downloadName}.h2k`, h2kData);
                    setExportErrors([]);
                }
            } catch (err) {
                console.log("Error exporting with results: ", err);
            }

            toggleExporting(false);
            return;
        } else {
            returnPackage = await exportH2kFile({
                modelId,
                returnType: "XML",
                h2kVersion,
                upgradePackage: upgradeSelected,
                appendResults: false,
                applyUpgradesToBase,
            });

            try {
                const { label: selectedLabel = "" } =
                    upgradeExportOpts.find(({ value }) => value === upgradeSelected) || {}; //adding upgrade name to file name

                if (returnPackage) {
                    //After some cleaning, h2kData is the xml string to download

                    const splitPackage = returnPackage.split("\r");

                    //Get initial and final indexes
                    const startInd = splitPackage.findIndex((el) => el.includes("<HouseFile")) || 3;
                    const endInd = splitPackage.findIndex((el) => el.includes("</HouseFile>")) || 3;

                    if (endInd === 3) {
                        h2kData = splitPackage[3];
                    } else {
                        h2kData = splitPackage.slice(startInd, endInd + 1).join("\r");
                    }

                    // h2kData = returnPackage.split('\r')[3]

                    if (h2kData.charAt(0) === "\r" || h2kData.charAt(0) === "\n") {
                        h2kData = h2kData.substring(1);
                    }

                    //Handle RURComments new line characters
                    // if (h2kData.includes("<RURComments")) {
                    //     h2kData = h2kData.replace("\r\n", "&#x0D;&#x0A;");
                    // }

                    const downloadName = selectedLabel
                        ? `${modelName}-${selectedLabel}`.replace(/\./g, " ")
                        : `${modelName}`.replace(/\./g, " ");

                    download(`${downloadName}.h2k`, h2kData);
                    setExportErrors([]);

                    if (exportErrors.length > 0) {
                        mixpanel.track("Model Export Proceed", {
                            "Model ID": modelId,
                            "Error Number": exportErrors.length,
                        });
                    } else {
                        mixpanel.track("Model Export", {
                            "Model ID": modelId,
                            "Has Errors": exportErrors.length > 0,
                            "Error Number": exportErrors.length,
                        });
                    }
                }
            } catch (err) {
                console.log("Error exporting without results: ", err);
            }

            toggleExporting(false);
        }

        toggleExporting(false);
    };

    const handleExport = async () => {
        toggleExporting(true);

        //handled in an action and cannot yet be disabled at the source, like handleSubmit
        // if (!readOnly) {
        //     await saveDrawingData();
        // }
        await modelSave();

        const errorList = await validateH2kExport({ modelId, h2kVersion });

        // If there are errors and it hasn't been run before
        if (exportErrors.length === 0 && errorList.length > 0) {
            toggleExporting(false);
            setExportErrors(errorList, "export", () => actionExport());
            return;
        }

        // Otherwise, continue
        actionExport();
    };

    return (
        <>
            <TabHeader
                className={classes.reviewHeader}
                title="Model Review"
                description="Summary information and model export to a .h2k file."
                detailsTrigger={() => toggleOpen(true)}
                detailsText="Review Details"
                noBorder
            />
            {/* <div className={classes.statsAndExport}>
                <div className={classes.statsAndButton}>
                    <Stats heatingDegreeDays={heatingDegreeDays} windowWallRatio={windowWallRatio} />
                </div>
            </div> */}
            <Tabs
                type="subTabs"
                initialTab={activeTab || ""}
                tabActive={activeTab || ""}
                onChange={(e) => {
                    history.push(`/models/${modelId}/review${e}`);
                    mixpanel.track("Review Tab Change", {
                        Tab: e,
                    });
                    return changeActiveTab(e);
                }}
                tabs={[
                    { name: "HOT2000 Results", url: "" },
                    { name: "Export to H2k", url: "?tab=export" },
                    { name: "Program", url: "?tab=program" },
                    { name: "Information", url: "?tab=information" },
                    { name: "CSA F280-12", url: "?tab=csaf280" },
                    { name: "Code Compliance", url: "?tab=compliance" },
                    ...(CHBA_NZEA
                        ? [{ name: "Net Zero Workbook", url: `?tab=workbook&subtab=${initialWorkbookSubtab}` }]
                        : []),
                    { name: "Utility Data", url: "?tab=utility" },
                ]}
                className={classes.tabs}
            >
                <div className={`${sharedClasses.section} ${classes.review}`}>
                    <Results
                        history={history}
                        modelSave={modelSave}
                        saveDrawingData={saveDrawingData}
                        readOnly={readOnly}
                    />
                </div>
                <div className={sharedClasses.section}>
                    <h4 style={{ marginBottom: "0.5rem" }}>HOT2000 Export</h4>
                    <div className={classes.export}>
                        <Select
                            className={classes.selectUpgrade}
                            name="exportIncluded"
                            options={[
                                {
                                    value: "",
                                    label: "None",
                                },
                                ...upgradeExportOpts,
                            ]}
                            label="Upgrade Included in .h2k File"
                            placeholder="None"
                            input={{
                                value: upgradeSelected,
                                onChange: (value) => toggleUpgradeSelected(value),
                            }}
                            large
                        />
                        <div
                            data-for={`non-ers-export-tooltip`}
                            data-tip={nonErsUser && programType !== "" ? "File must be in General mode." : ""}
                        >
                            <Button
                                smallPadding
                                onClick={handleExport}
                                disabled={exporting || exportingDcf || (nonErsUser && programType !== "")}
                                icon={Export}
                                className={classes.exportButton}
                            >
                                {exporting ? (
                                    "Exporting..."
                                ) : (
                                    <span>
                                        Save and Export{" "}
                                        <span className={classes.h2kV}>({h2kVersion.split("-").join(".")})</span>
                                    </span>
                                )}
                            </Button>
                        </div>
                        <Tooltip id={"non-ers-export-tooltip"} />
                    </div>
                    <div className={classes.exportParameterContainer}>
                        <Checkbox
                            name="applyUpgradesToBase"
                            label="Apply upgrades to base file"
                            large
                            input={{
                                value: applyUpgradesToBase,
                                onChange: () => toggleApplyUpgradesToBase(!applyUpgradesToBase),
                            }}
                        />
                        <Checkbox
                            name="exportWithResults"
                            label="Export file with results"
                            large
                            input={{
                                value: exportWithResults,
                                onChange: () => toggleExportWithResults(!exportWithResults),
                            }}
                        />
                    </div>
                    {weatherProvTerrId === 6 && (
                        <div className={classes.fuelLibContainer}>
                            <Field
                                component={Select}
                                name="modelData.fuelCosts.params.overrideFuelLib"
                                label="Fuel Library"
                                options={[
                                    {
                                        label: "None",
                                        value: "",
                                    },
                                    {
                                        label: "FuelLib AEE07-2010_v11.58.FLC",
                                        value: "QC_AEE07_2010_fuellib",
                                    },
                                ]}
                            />
                        </div>
                    )}

                    <h4 style={{ marginTop: "2rem", marginBottom: "0.5rem" }}>Additional Exports</h4>
                    <div className={classes.exportDcf}>
                        <DcfExport
                            fileName={modelName}
                            modelSave={modelSave}
                            saveDrawingData={saveDrawingData}
                            readOnly={readOnly}
                            h2kVersion={h2kVersion}
                            exporting={exporting}
                            exportingDcf={exportingDcf}
                            toggleDcfExporting={toggleDcfExporting}
                            toggledcfErrorMsg={toggledcfErrorMsg}
                        />
                        {dcfErrorMsg && <p className={classes.errorMsg}>{dcfErrorMsg}</p>}
                    </div>
                </div>

                <Program
                    programType={programType}
                    change={change}
                    provTerrId={weatherProvTerrId}
                    h2kVersion={h2kVersion}
                    nonErsUser={nonErsUser}
                />
                <Information accessor={"modelData.information"} change={change} />
                <F280Summary
                    change={change}
                    history={history}
                    modelId={modelId}
                    modelSave={modelSave}
                    saveDrawingData={saveDrawingData}
                    readOnly={readOnly}
                    upgradeExportOpts={upgradeExportOpts}
                />
                <div className={`${sharedClasses.section} ${classes.review}`}>
                    <SummaryTable change={change} />
                </div>
                {CHBA_NZEA && (
                    <div>
                        <NetZeroWorkbook
                            history={history}
                            modelId={modelId}
                            change={change}
                            changeActiveTab={changeActiveTab}
                        />
                    </div>
                )}
                <div>
                    <UtilityData accessor={`modelData.utilityData`} history={history} change={change} />
                </div>
            </Tabs>
            <div
                className={`${sharedClasses.section} ${classes.review}`}
                data-for="exportTip"
                data-tip={isInvalid ? "Please resolve invalid fields." : "Save and export in .h2k file"}
            >
                <DetailsDrawer open={open} close={() => toggleOpen(false)} title="Review Tab Details">
                    <Details />
                    <div className={classes.buttons}>
                        <Button large type="hollow" onClick={() => toggleOpen(false)}>
                            Close
                        </Button>
                    </div>
                </DetailsDrawer>
                <div className={classes.errorReporting}>
                    <h4>Error Reporting</h4>
                    <p>
                        Files with errors can now be flagged for review in the model settings drawer above, indicated by
                        this icon: <SettingsIcon />
                    </p>
                </div>
            </div>
            <Dialog
                open={exportErrors.length > 0}
                classes={{
                    rootClass: classes.errorRoot,
                    paperClass: classes.errorPaper,
                }}
                maxWidth="lg"
            >
                <div className={classes.errorContent}>
                    <ErrorList errorList={exportErrors || []} />
                </div>
                {exportErrors.length && (
                    <div className={classes.issuesButtons}>
                        <Button onClick={() => setExportErrors([])} type="hollow">
                            Cancel
                        </Button>
                        <Button onClick={errorProceedFunc}>{errorLocationText[errorLocation]}</Button>
                    </div>
                )}
            </Dialog>
        </>
    );
};
