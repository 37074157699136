import React from 'react';
import classes from './style.module.scss';
import sharedClasses from 'features/Model/sharedStyles.module.scss';
import { Field } from 'redux-form';
import { required, maxLength, getValidation, getDecimalPlaces } from 'utils/fieldValidation';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions, getDefaultOption } from 'utils/fields';
import InputRow from 'components/Input/Row';
import Input from 'components/Input';
import InputWithUnits from 'components/Input/InputWithUnits';
import Select from 'components/Input/Select';
import SelectWithInput from 'components/Input/SelectWithInput';
import Button from 'components/Button';
import Checkbox from 'components/Input/Checkbox';

const numAdultsValidation = [required, ...getValidation('loadsNumAdults')];
const numAdultsWholeMurbValidation = [required, ...getValidation('loadsNumAdultsWholeMurb')];
const atHomeAdultsValidation = [required, ...getValidation('loadsAtHomeAdults')];
const numChildrenValidation = [required, ...getValidation('loadsNumChildren')];
const atHomeChildrenValidation = [required, ...getValidation('loadsAtHomeChildren')];
const numInfantsValidation = [required, ...getValidation('loadsNumInfants')];
const atHomeInfantsValidation = [required, ...getValidation('loadsAtHomeInfants')];
const basementIntGainsValidation = [required, ...getValidation('loadsBasementIntGainsFraction')];
const summaryElecAppliancesValidation = getValidation('loadsElecApplConsumption');
const summaryLightingValidation = getValidation('loadsLightingConsumption');
const summaryOtherValidation = getValidation('loadsOtherElecConsumption');
const summaryExteriorValidation = getValidation('loadsExteriorUseConsumption');
const summaryHotWaterValidation = getValidation('loadsHotWaterLoad');
const advHotWaterTempValidation = getValidation('loadsAdvHotWaterTemp');
const advGasStoveValidation = getValidation('loadsAdvGasStove');
const advGasDryerValidation = getValidation('loadsAdvGasDryer');
const advDryerLocationValidation = getValidation('loadsAdvDryerLocation');
const commonSpaceValidation = getValidation('loadsCommonSpaceConsumption');

// Note that this does not yet support dryer locations in foundation components
// Also must determine which values are connected

export default React.memo(({
    accessor,
    isOccupied,
    totalOccupants,
    isMURB,
    isWholeBuildingMURB,
    totalMURBUnits,
    isAdvUserSpec,
    isAdvGasStove,
    isAdvGasDryer,
    advHotWaterTempValue,
    gasStoveConsumptionValue,
    gasDryerConsumptionValue,
    atHomeAdultsUnits,
    atHomeChildrenUnits,
    atHomeInfantsUnits,
    basementIntGainsUnits,
    summaryElecAppliancesUnits,
    summaryLightingUnits,
    summaryOtherUnits,
    summaryExteriorUnits,
    summaryHotWaterUnits,
    advHotWaterTempUnits,
    advGasStoveUnits,
    advGasDryerUnits,
    commonSpaceConsumptionUnits,
    modelUnits,
    change
}) => {

    return (
        <div>
            <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Daily Consumption Summary</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.summary.elecApplConsumption`}
                    label="Electrical Appliances"
                    placeholder="0.00"
                    validate={summaryElecAppliancesValidation}
                    decimals={getDecimalPlaces('loadsElecApplConsumption')}
                    change={change}
                    disabled={!isAdvUserSpec}
                    setValue={!isAdvUserSpec ? (isMURB ? 0.94*totalOccupants + 3.3*(totalMURBUnits || 1) : 1.51*totalOccupants + 1.75) : null}
                    units={{
                        base:getBaseUnits('loadsElecApplConsumption', modelUnits),
                        options:getUnitOptions('energy'),
                        selected:summaryElecAppliancesUnits,
                        unitType:'energy',
                        accessor:`${accessor}.summary.elecApplConsumption_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.summary.lightingConsumption`}
                    label="Lighting"
                    placeholder="0.00"
                    validate={summaryLightingValidation}
                    decimals={getDecimalPlaces('loadsLightingConsumption')}
                    change={change}
                    disabled={!isAdvUserSpec}
                    setValue={isMURB ?  1.7*(totalMURBUnits || 1) : 2.6}
                    units={{
                        base:getBaseUnits('loadsLightingConsumption', modelUnits),
                        options:getUnitOptions('energy'),
                        selected:summaryLightingUnits,
                        unitType:'energy',
                        accessor:`${accessor}.summary.lightingConsumption_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.summary.exteriorUseConsumption`}
                    label="Average Exterior Use"
                    placeholder="0.00"
                    validate={summaryExteriorValidation}
                    decimals={getDecimalPlaces('loadsExteriorUseConsumption')}
                    change={change}
                    disabled={!isAdvUserSpec}
                    setValue={isMURB ? 0.4*(totalMURBUnits || 1) : 0.9}
                    units={{
                        base:getBaseUnits('loadsExteriorUseConsumption', modelUnits),
                        options:getUnitOptions('energy'),
                        selected:summaryExteriorUnits,
                        unitType:'energy',
                        accessor:`${accessor}.summary.exteriorUseConsumption_u`
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.summary.otherElecConsumption`}
                    label="Other Electricity Consumption"
                    placeholder="0.00"
                    validate={summaryOtherValidation}
                    decimals={getDecimalPlaces('loadsOtherElecConsumption')}
                    change={change}
                    disabled={!isAdvUserSpec}
                    setValue={isMURB ? 4.4*(totalMURBUnits || 1) : 9.7}
                    units={{
                        base:getBaseUnits('loadsOtherElecConsumption', modelUnits),
                        options:getUnitOptions('energy'),
                        selected:summaryOtherUnits,
                        unitType:'energy',
                        accessor:`${accessor}.summary.otherElecConsumption_u`
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.summary.hotWaterLoad`}
                    label="Hot Water Load"
                    placeholder="0.00"
                    validate={summaryHotWaterValidation}
                    decimals={getDecimalPlaces('loadsHotWaterLoad')}
                    change={change}
                    disabled={!isAdvUserSpec}
                    setValue={!isAdvUserSpec ? (isMURB ? 64.18*totalOccupants : 63.1*totalOccupants) : null}
                    units={{
                        base:getBaseUnits('loadsHotWaterLoad', modelUnits),
                        options:getUnitOptions('volume'),
                        selected:summaryHotWaterUnits,
                        unitType:'volume',
                        accessor:`${accessor}.summary.hotWaterLoad_u`
                    }}
                />
            </InputRow>
            <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Occupancy</h4>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr 1fr">
                <Field
                    component={Checkbox}
                    name={`${accessor}.occupancy.isOccupied`}
                    label="Is Occupied?"
                    large
                    className={classes.bottomCheckBox}
                    type="checkbox"
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                <div className={classes.groupings}>
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.occupancy.numAdults`}
                        label="Adult Occupants"
                        placeholder={0.00}
                        validate={isWholeBuildingMURB ? numAdultsWholeMurbValidation: numAdultsValidation}
                        decimals={getDecimalPlaces('loadsNumAdults')}
                        disabled={!isOccupied}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.occupancy.atHomeAdults`}
                        label="Time at Home"
                        placeholder="0.00"
                        validate={atHomeAdultsValidation}
                        decimals={getDecimalPlaces('loadsAtHomeAdults')}
                        change={change}
                        disabled={!isOccupied}
                        units={{
                            base:getBaseUnits('loadsAtHomeAdults', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:atHomeAdultsUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.occupancy.atHomeAdults_u`
                        }}
                    />
                </div>
                <div className={classes.groupings}>
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.occupancy.numChildren`}
                        label="Child Occupants"
                        placeholder={0.00}
                        validate={numChildrenValidation}
                        decimals={getDecimalPlaces('loadsNumChildren')}
                        disabled={!isOccupied}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.occupancy.atHomeChildren`}
                        label="Time at Home"
                        placeholder="0.00"
                        validate={atHomeChildrenValidation}
                        decimals={getDecimalPlaces('loadsAtHomeChildren')}
                        change={change}
                        disabled={!isOccupied}
                        units={{
                            base:getBaseUnits('loadsAtHomeChildren', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:atHomeChildrenUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.occupancy.atHomeChildren_u`
                        }}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                <div className={classes.groupings}>
                    <Field
                        component={Input}
                        type="number"
                        name={`${accessor}.occupancy.numInfants`}
                        label="Infant Occupants"
                        placeholder={0.00}
                        validate={numInfantsValidation}
                        decimals={getDecimalPlaces('loadsNumInfants')}
                        disabled={!isOccupied}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.occupancy.atHomeInfants`}
                        label="Time at Home"
                        placeholder="0.00"
                        validate={atHomeInfantsValidation}
                        decimals={getDecimalPlaces('loadsAtHomeInfants')}
                        change={change}
                        disabled={!isOccupied}
                        units={{
                            base:getBaseUnits('loadsAtHomeInfants', modelUnits),
                            options:getUnitOptions('fraction'),
                            selected:atHomeInfantsUnits,
                            unitType:'fraction',
                            accessor:`${accessor}.occupancy.atHomeInfants_u`
                        }}
                    />
                </div>
            </InputRow>
            <h4 style={{marginBottom:"1.25rem", marginTop:"1.75rem"}}>Internal Gains</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.basementFractionOfInternalGains`}
                    label="Fraction Applied to Basement"
                    placeholder={0.00}
                    validate={basementIntGainsValidation}
                    decimals={getDecimalPlaces('loadsBasementIntGainsFraction')}
                    change={change}
                    units={{
                        base:getBaseUnits('loadsBasementIntGainsFraction', modelUnits),
                        options:getUnitOptions('fraction'),
                        selected:basementIntGainsUnits,
                        unitType:'fraction',
                        accessor:`${accessor}.basementFractionOfInternalGains_u`
                    }}
                />
            </InputRow>
            {
                isWholeBuildingMURB ?
                    <>
                        <h4>MURB Parameters</h4>
                        <InputRow gridTemplate="1fr 1fr 1fr">
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.commonSpaceElectricalConsumption`}
                                label="Common Space Electrical Consumption"
                                placeholder="0.00"
                                validate={commonSpaceValidation}
                                decimals={getDecimalPlaces('loadsCommonSpaceConsumption')}
                                change={change}
                                disabled={!isWholeBuildingMURB}
                                units={{
                                    base:getBaseUnits('loadsCommonSpaceConsumption', modelUnits),
                                    options:getUnitOptions('energy'),
                                    selected:commonSpaceConsumptionUnits,
                                    unitType:'energy',
                                    accessor:`${accessor}.commonSpaceElectricalConsumption_u`
                                }}
                            />
                        </InputRow>
                    </> : <></>
            }
            {
                isAdvUserSpec ? 
                    <>
                        <h4>Advanced User Specified</h4>
                        <InputRow gridTemplate="1fr 1fr 1fr">
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.advanced.userSpecified.hotWaterTemp`}
                                label="Hot Water Temperature"
                                placeholder={0.00}
                                validate={advHotWaterTempValidation}
                                decimals={getDecimalPlaces('loadsAdvHotWaterTemp')}
                                change={change}
                                setValue={advHotWaterTempValue == null ? getDefaultOption('loadsAdvHotWaterTemp') : advHotWaterTempValue}
                                units={{
                                    base:getBaseUnits('loadsAdvHotWaterTemp', modelUnits),
                                    options:getUnitOptions('temperature'),
                                    selected:advHotWaterTempUnits,
                                    unitType:'temperature',
                                    accessor:`${accessor}.advanced.userSpecified.hotWaterTemp_u`
                                }}
                            />
                        </InputRow>
                        <InputRow gridTemplate="31.85% 23.25% 23.25%">
                            <Field
                                component={Select}
                                name={`${accessor}.advanced.userSpecified.gasStove`}
                                options={[
                                    {
                                        label:"Natural Gas",
                                        value:{
                                            id:0,
                                            value:gasStoveConsumptionValue, //need to get rValue for this to match
                                        }
                                    },
                                    {
                                        label:"Propane",
                                        value:{
                                            id:1,
                                            value:gasStoveConsumptionValue, //need to get rValue for this to match
                                        }
                                    }
                                ]}
                                label="Gas Stove Fuel Type"
                                placeholder="Choose Fuel Type"
                                validate={advGasStoveValidation}
                                disabled={!isAdvGasStove}
                            />
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.advanced.userSpecified.gasStove.value`}
                                label="Daily Consumption"
                                placeholder={0.00}
                                disabled={!isAdvGasStove || !isAdvUserSpec}
                                validate={advGasStoveValidation}
                                setTouched={true}
                                decimals={getDecimalPlaces('loadsAdvGasStove')}
                                change={change}
                                units={{
                                    base:getBaseUnits('loadsAdvGasStove', modelUnits),
                                    options:getUnitOptions('energy'),
                                    selected:advGasStoveUnits,
                                    unitType:'energy',
                                    accessor:`${accessor}.advanced.userSpecified.gasStove_u`
                                }}
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.advanced.userSpecified.gasStoveEnabled`}
                                label="Gas Stove Present?"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                        </InputRow>
                        <InputRow gridTemplate="31.85% 23.25% 23.25%">
                            <Field
                                component={Select}
                                name={`${accessor}.advanced.userSpecified.gasDryer`}
                                options={[
                                    {
                                        label:"Natural Gas",
                                        value:{
                                            id:0,
                                            value:gasDryerConsumptionValue, //need to get rValue for this to match
                                        }
                                    },
                                    {
                                        label:"Propane",
                                        value:{
                                            id:1,
                                            value:gasDryerConsumptionValue, //need to get rValue for this to match
                                        }
                                    }
                                ]}
                                label="Gas Dryer Fuel Type"
                                placeholder="Choose Fuel Type"
                                validate={advGasDryerValidation}
                                disabled={!isAdvGasDryer}
                            />
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.advanced.userSpecified.gasDryer.value`}
                                label="Daily Consumption"
                                placeholder={0.00}
                                disabled={!isAdvGasDryer || !isAdvUserSpec}
                                validate={advGasDryerValidation}
                                setTouched={true}
                                decimals={getDecimalPlaces('loadsAdvGasDryer')}
                                change={change}
                                units={{
                                    base:getBaseUnits('loadsAdvGasDryer', modelUnits),
                                    options:getUnitOptions('energy'),
                                    selected:advGasDryerUnits,
                                    unitType:'energy',
                                    accessor:`${accessor}.advanced.userSpecified.gasDryer_u`
                                }}
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.advanced.userSpecified.gasDryerEnabled`}
                                label="Gas Dryer Present?"
                                large
                                className={classes.bottomCheckBox}
                                type="checkbox"
                            />
                        </InputRow>
                        <InputRow gridTemplate="1fr 1fr 1fr">
                            <Field
                                component={Select}
                                name={`${accessor}.advanced.userSpecified.gasStove`}
                                options={getOptions({fieldKey: 'loadsAdvDryerLocation'})}
                                label="Dryer Location"
                                placeholder="Choose Dryer Location"
                                validate={advDryerLocationValidation}
                            />
                        </InputRow>
                    </>
                : <></>
            }
        </div>
    )   
})