import React from "react";

import F280FirstPage from "../F280FirstPage";

import logoSVG from "assets/images/logo-color.svg";
import HVACDCLogo from "../F280FirstPage/HVACDCLogo.png";

import "../F280FirstPage/styles.css";

const F280PDF = ({
    pdfRef,
    displayData,
    inputFieldsData,
    calcPerformedBy,
    name,
    heatLossValue,
    latHeatGainValue,
    heatUnit,
    // notes,
}) => {
    const { issuedForTheUseOf, projectNo, lot, site, dimInfoBasedOn } = inputFieldsData;

    const { buildingLocation = {}, calculationBasedOn = {}, components = {}, designConditions } = displayData || {};

    const { cityProvince = "", postalCode = "", address = "" } = buildingLocation || {};

    const {
        airtightness = "",
        airtightnessAssumed = "",
        attachment = "",
        // dimInfoBasedOn = "",
        frontFacing = "",
        frontFacingAssumed = "",
        hrv = "",
        internalShading = "",
        internalShadingAssumed = "",
        numStoreys = "",
        occupants = "",
        occupantsAssumed = "",
        windSheltering = "",
        recoveryEff = "",
        units = "",
        ventilated = "",
        weatherLocation = "",
        windExposure = "",
        windShielding,
        hrvAtre,
        soilConductivity,
    } = calculationBasedOn || {};

    const { cooling = {}, heating = {} } = designConditions || {};

    const {
        aboveGradeWalls = [],
        belowGradeWalls = [],
        ceilings = [],
        doors = [],
        exposedFloors = [],
        floorsOnSoil = [],
        skylights = [],
        windows = [],
    } = components || {};

    const { userName } = calcPerformedBy || {};

    return (
        <div ref={pdfRef} id="f280Print">
            <F280FirstPage
                heatLossValue={heatLossValue}
                latHeatGainValue={latHeatGainValue}
                heatUnit={heatUnit}
                name={name}
                userName={userName}
                inputFieldsData={inputFieldsData}
                displayData={displayData}
                calcPerformedBy={calcPerformedBy}
            />
            <div className="body">
                <div className="titleContainer">
                    <div className="titleWrapper">
                        <div className="titleSubtitleWrapper">
                            <h1 className="titleWrapperH1">CSA STANDARD F280-12 COMPLIANCE</h1>
                            <span className="subtitle">
                                NBC 2015: 9.33.5.1.; 9.36.3.2. & 9.36.5.15; NBC 2020; 9.33.5.1.; 9.36.3.2.; 9.36.5.15
                                (5); 9.36.8.9. (1)
                            </span>
                        </div>
                        <div className="rightSubContainer">
                            <span className="titleWrapperSpan">
                                CSA-F280-M12 <br />
                                Standard Form No. 1
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell cellFullFlex  paddingRight" style={{ marginBottom: "0.5rem" }}>
                        <div className="nameContainer">
                            <p className="regText">These documents issued for the use of</p>
                            <div className="inputName">
                                <span className="nameContainerSpan">{issuedForTheUseOf}</span>
                            </div>
                        </div>
                        <p className="regText">
                            and may not be used by any other persons without authorization. Documents for permit and/or
                            construction are signed in red.
                        </p>
                        <span className="smallNumberFields">1</span>
                    </div>
                    <div className="cell" style={{ padding: 0, borderLeft: "2px solid black", width: "150px" }}>
                        <div className="projectNumber">
                            <span className="projectNumberSpan">PROJECT #</span>
                            <p className="projectNumberP">{projectNo}</p>
                        </div>
                        <span className="smallNumberFields">2</span>
                    </div>
                </div>
                <div className="smallTitle">
                    <h2>BUILDING LOCATION</h2>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>Model:</span>
                            <p>{name}</p>
                        </div>
                        <span className="smallNumberFields">3</span>
                    </div>
                    <div className="cellFullFlex" style={{ display: "flex" }}>
                        <div className="cellSmallPadding cellFullFlex borderRight">
                            <div className="cellTextContainer">
                                <span>Site:</span>
                                <p>{site}</p>
                            </div>
                            <span className="smallNumberFields">6</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer">
                                <span>Lot:</span>
                                <p>{lot}</p>
                            </div>
                            <span className="smallNumberFields">7</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>Address:</span>
                            <p>{address}</p>
                        </div>
                        <span className="smallNumberFields">3</span>
                    </div>
                    <div className="cellFullFlex" style={{ display: "flex" }}>
                        <div className="cellSmallPadding cellFullFlex borderRight">
                            <div className="cellTextContainer">
                                <span style={{ minWidth: "40px" }}>
                                    City/
                                    <br /> Prov:
                                </span>
                                <p>{cityProvince}</p>
                            </div>
                            <span className="smallNumberFields">5</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer">
                                <span>
                                    Post.
                                    <br /> code:
                                </span>
                                <p>{postalCode}</p>
                            </div>
                            <span className="smallNumberFields">8</span>
                        </div>
                    </div>
                </div>

                <div className="smallTitle">
                    <h2>CALCULATION BASED ON</h2>
                    <span className="titleSupText">(See Following Page For Results)</span>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex">
                        <div className="cellTextContainer">
                            <span>Dimensional information based on:</span>
                            <p>{dimInfoBasedOn}</p>
                        </div>
                        <span className="smallNumberFields">9</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>Attachment:</span>
                            <p>{attachment}</p>
                        </div>
                        <span className="smallNumberFields">10</span>
                    </div>
                    <div className="cellFullFlex cellSmallPadding" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Front facing:</span>
                                <p>{frontFacing}</p>
                            </div>
                            <span className="smallNumberFields">16</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>Assumed?</span>
                                <p>{frontFacingAssumed}</p>
                            </div>
                            <span className="smallNumberFields">17</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>#. of stories:</span>
                            <p>{numStoreys}</p>
                        </div>
                        <span className="smallNumberFields">11</span>
                    </div>
                    <div className="cellFullFlex cellSmallPadding" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Air tightness:</span>
                                <p>{airtightness}</p>
                            </div>
                            <span className="smallNumberFields">18</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>Assumed?</span>
                                <p>{airtightnessAssumed}</p>
                            </div>
                            <span className="smallNumberFields">19</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>Weather location:</span>
                            <p>{weatherLocation}</p>
                        </div>
                        <span className="smallNumberFields">1</span>
                    </div>
                    <div className="cellFullFlex cellSmallPadding" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Internal Shading: ?</span>
                                <p>{internalShading}</p>
                            </div>
                            <span className="smallNumberFields">21</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>Assumed?</span>
                                <p>{internalShadingAssumed}</p>
                            </div>
                            <span className="smallNumberFields">21a</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>Wind Exposure:</span>
                            <p>{windExposure}</p>
                        </div>
                        <span className="smallNumberFields">20</span>
                    </div>
                    <div className="cellFullFlex cellSmallPadding" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Occupants</span>
                                <p>{occupants}</p>
                            </div>
                            <span className="smallNumberFields">22</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>Assumed?</span>
                                <p>{occupantsAssumed}</p>
                            </div>
                            <span className="smallNumberFields">22a</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainer">
                            <span>Wind Sheltering:</span>
                            <p>{windSheltering}</p>
                        </div>
                        <span className="smallNumberFields">20a</span>
                    </div>
                    <div className="cellFullFlex cellSmallPadding" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Ventilated?</span>
                                <p>{ventilated}</p>
                            </div>
                            <span className="smallNumberFields">13</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>HRV/ERV?</span>
                                <p>{hrv}</p>
                            </div>
                            <span className="smallNumberFields">14</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="cellSmallPadding cellFullFlex borderRight cellTextContainerSmall"
                        style={{ display: "flex", gap: "10px", alignItems: "center" }}
                    >
                        <span style={{ width: "130px", textAlign: "center" }}>Units:</span>
                        <div className="selectionBoxContainer">
                            <div className="selectionBoxWrapper">
                                <div className="boxContainer">
                                    <span className="boxX">{units === "Metric" ? "X" : ""}</span>
                                </div>
                                <span className="boxText">Metric</span>
                            </div>
                            <div className="selectionBoxWrapper">
                                <div className="boxContainer">
                                    <span className="boxX">{units === "Imperial" ? "X" : ""}</span>
                                </div>
                                <span className="boxText">Imperial</span>
                            </div>
                        </div>
                        <span className="smallNumberFields">23</span>
                    </div>
                    <div className="cellFullFlex cellSmallPadding" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>ASE %:</span>
                                <p>{recoveryEff}</p>
                            </div>
                            <span className="smallNumberFields">15</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>ATRE %:</span>
                                <p>{hrvAtre}</p>
                            </div>
                            <span className="smallNumberFields">15a</span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="smallTitle cellFullFlex noBorderBottom borderRight">
                        <h2>HEATING DESIGN CONDITIONS</h2>
                    </div>
                    <div className="smallTitle cellFullFlex noBorderBottom">
                        <h2>COOLING DESIGN CONDITIONS</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="cellFullFlex cellSmallPadding borderRight" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Outdoor Temp:</span>
                                <p>{heating?.outdoorTemp ? heating?.outdoorTemp : ""}</p>
                            </div>
                            <span className="smallNumberFields">24</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>Indoor Temp:</span>
                                <p>{heating?.indoorTemp ? heating?.indoorTemp : ""}</p>
                            </div>
                            <span className="smallNumberFields">25</span>
                        </div>
                    </div>
                    <div className="cellFullFlex cellSmallPadding" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Outdoor Temp:</span>
                                <p>{cooling?.outdoorTemp ? cooling?.outdoorTemp : ""}</p>
                            </div>
                            <span className="smallNumberFields">27</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>Range:</span>
                                <p>{cooling?.meanTempRange ? cooling?.meanTempRange : ""}</p>
                            </div>
                            <span className="smallNumberFields">29</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cellFullFlex cellSmallPadding borderRight" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Mean Soil Temp:</span>
                                <p>{heating?.meanSoilTemp ? heating?.meanSoilTemp : ""}</p>
                            </div>
                            <span className="smallNumberFields">26</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>
                                    Soil
                                    <br />
                                    Conductivity
                                </span>
                                <p>{soilConductivity}</p>
                            </div>
                            <span className="smallNumberFields">26a</span>
                        </div>
                    </div>
                    <div className="cellFullFlex cellSmallPadding" style={{ display: "flex" }}>
                        <div className=" cellSmallPadding cellFullFlex">
                            <div className="cellTextContainer cellFullFlex">
                                <span>Indoor Temp:</span>
                                <p>{cooling?.indoorTemp ? cooling?.indoorTemp : ""}</p>
                            </div>
                            <span className="smallNumberFields">28</span>
                        </div>
                        <div style={{ height: "117%", marginTop: "-3px", width: "2px", background: "black" }}></div>
                        <div className="cellFlex cellFullFlex">
                            <div className="cellSmallPadding cellTextContainer cellTextContainerSmall cellFullFlex">
                                <span>latitude:</span>
                                <p>{cooling?.latitude ? cooling?.latitude : ""}</p>
                            </div>
                            <span className="smallNumberFields">30</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="smallTitle cellFullFlex noBorderBottom borderRight">
                        <h2>ABOVE GRADE WALLS</h2>
                    </div>
                    <div className="smallTitle cellFullFlex noBorderBottom">
                        <h2>BELOW GRADE WALLS</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style A:</span>
                            <p>{aboveGradeWalls[0] ? aboveGradeWalls[0] : ""}</p>
                        </div>
                        <span className="smallNumberFields">31</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style A:</span>
                            <p>{belowGradeWalls[0] ? belowGradeWalls[0] : ""}</p>
                        </div>
                        <span className="smallNumberFields">34</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style B:</span>
                            <p>{aboveGradeWalls[1] ? aboveGradeWalls[1] : ""}</p>
                        </div>
                        <span className="smallNumberFields">32</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style B:</span>
                            <p>{belowGradeWalls[1] ? belowGradeWalls[1] : ""}</p>
                        </div>
                        <span className="smallNumberFields">35</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style C:</span>
                            <p>{aboveGradeWalls[2] ? aboveGradeWalls[2] : ""}</p>
                        </div>
                        <span className="smallNumberFields">33</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style C:</span>
                            <p>{belowGradeWalls[2] ? belowGradeWalls[2] : ""}</p>
                        </div>
                        <span className="smallNumberFields">36</span>
                    </div>
                </div>
                <div className="row">
                    <div className="smallTitle cellFullFlex noBorderBottom borderRight">
                        <h2>Ceilings</h2>
                    </div>
                    <div className="smallTitle cellFullFlex noBorderBottom ">
                        <h2>Floors on soil</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style A:</span>
                            <p>{ceilings[0] ? ceilings[0] : ""} </p>
                        </div>
                        <span className="smallNumberFields">40</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex ">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style A:</span>
                            <p>{floorsOnSoil[0] ? floorsOnSoil[0] : ""} </p>
                        </div>
                    </div>
                    <span className="smallNumberFields">37</span>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style B:</span>
                            <p>{ceilings[1] ? ceilings[1] : ""} </p>
                        </div>
                        <span className="smallNumberFields">41</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex ">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style B:</span>
                            <p>{floorsOnSoil[1] ? floorsOnSoil[1] : ""} </p>
                        </div>
                        <span className="smallNumberFields">38</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cellSmallPadding cellFullFlex borderRight">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style C:</span>
                            <p>{ceilings[2] ? ceilings[2] : ""} </p>
                        </div>
                        <span className="smallNumberFields">42</span>
                    </div>
                    <div className="cellSmallPadding cellFullFlex ">
                        <div className="cellTextContainerSmall cellTextContainer">
                            <span>Style C:</span>
                            <p>{floorsOnSoil[2] ? floorsOnSoil[2] : ""} </p>
                        </div>
                        <span className="smallNumberFields">39</span>
                    </div>
                </div>
                <div className="row noBorderBottom">
                    <div className="columnA cellFullFlex">
                        <div className="smallTitle cellFullFlex noPadding borderBottom">
                            <h2>Windows</h2>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style A:</span>
                                <p>{windows[0] ? windows[0] : ""} </p>
                            </div>
                            <span className="smallNumberFields">49</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style B:</span>
                                <p>{windows[1] ? windows[1] : ""} </p>
                            </div>
                            <span className="smallNumberFields">50</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style C:</span>
                                <p>{windows[2] ? windows[2] : ""} </p>
                            </div>
                            <span className="smallNumberFields">51</span>
                        </div>
                    </div>
                    <div className="columnB cellFullFlex">
                        <div className="smallTitle cellFullFlex noPadding borderBottom">
                            <h2>Exposed floors</h2>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style A:</span>
                                <p>{exposedFloors[0] ? exposedFloors[0] : ""} </p>
                            </div>
                            <span className="smallNumberFields">43</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style B:</span>
                                <p>{exposedFloors[1] ? exposedFloors[1] : ""} </p>
                            </div>
                            <span className="smallNumberFields">44</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style C:</span>
                                <p>{exposedFloors[2] ? exposedFloors[2] : ""} </p>
                            </div>
                            <span className="smallNumberFields">45</span>
                        </div>
                    </div>
                </div>
                <div className="row noBorderBottom">
                    <div className="columnA cellFullFlex">
                        <div className="smallTitle cellFullFlex noPadding borderBottom">
                            <h2>SKYLIGHTS</h2>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style A:</span>
                                <p>{skylights[0] ? skylights[0] : ""} </p>
                            </div>
                            <span className="smallNumberFields">52</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style B:</span>
                                <p>{skylights[1] ? skylights[1] : ""} </p>
                            </div>
                            <span className="smallNumberFields">53</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style C:</span>
                                <p>{skylights[2] ? skylights[2] : ""} </p>
                            </div>
                            <span className="smallNumberFields">54</span>
                        </div>
                    </div>
                    <div className="columnB cellFullFlex">
                        <div className="smallTitle cellFullFlex noPadding borderBottom">
                            <h2>Doors</h2>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style A:</span>
                                <p>{doors[0] ? doors[0] : ""} </p>
                            </div>
                            <span className="smallNumberFields">46</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style B:</span>
                                <p>{doors[1] ? doors[1] : ""} </p>
                            </div>
                            <span className="smallNumberFields">47</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex borderBottom">
                            <div className="cellTextContainerSmall cellTextContainer">
                                <span>Style C:</span>
                                <p>{doors[2] ? doors[2] : ""} </p>
                            </div>
                            <span className="smallNumberFields">48</span>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ height: "30px" }}>
                    <div className="cellFullFlex borderRight"></div>
                    <div className="cellFullFlex" style={{ display: "flex" }}>
                        <div className="cellSmallPadding cellFullFlex borderRight">
                            <div className="cellTextContainer">
                                <span style={{ minWidth: "40px" }}>Issued:</span>
                                <p></p>
                            </div>
                            <span className="smallNumberFields">76</span>
                        </div>
                        <div className="cellSmallPadding cellFullFlex">
                            <div
                                className=""
                                style={{
                                    fontWeight: "700",
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                    gap: "10px",
                                    fontSize: "0.9rem",
                                    justifyContent: "center",
                                }}
                            >
                                <span>Page:</span>
                                <span> 2</span> <span>of</span>
                                <span>2</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row noBorderBottom">
                    <div
                        style={{
                            flex: "2 1 4.7%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            padding: "10px 0",
                        }}
                    >
                        <img src={logoSVG} />
                        <span
                            style={{
                                position: "absolute",
                                bottom: "2px",
                                right: 0,
                                left: 0,
                                margin: "0 auto",
                                textAlign: "center",
                                fontSize: "0.6rem",
                            }}
                        >
                            Version 0.2.3. Contact: info@voltaresearch.org
                        </span>
                        <span className="smallNumberFields">63</span>
                    </div>
                    <div
                        style={{
                            flex: "1",
                            borderLeft: "2px solid",
                            paddingLeft: "1px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px",
                        }}
                    >
                        <img src={HVACDCLogo} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default F280PDF;
