import React from "react";
import classes from "../style.module.scss";
import { useHistory, useLocation } from "react-router";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserModelDir, selectUserModelDirLoading, selectUserName } from "store/users/selectors";
import { selectModelLoading } from "features/Model/_ducks/selectors";
import { actions as userActions } from "store/users";
import { actions as modelActions } from "features/Model/_ducks";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import { fetchDrawingData } from "features/Model/DrawingNew/_ducks/thunk";
import { clearDrawingData } from "features/Model/DrawingNew/_ducks/actions";
import DashboardLayout from "features/Dashboards";
import Button from "components/Button";
import Loading from "components/Loading";
import dashboardTemp from "assets/images/dashboard_temp.jpg";
import AddIcon from "assets/images/icons/JSX/Add";
import HouseImage from "assets/images/house-structure.svg";
import SectionHeading from "components/SectionHeading";
import CodeLibIcon from "assets/images/icons/JSX/CodeLib";
import HouseIcon from "assets/images/icons/JSX/House";
import { CreateFirstModel } from "components/Dashboard/Actions/CreateFirstDialog";

const { clearModelData, fetchModelData, modelLoading, setExportErrors, deleteModel, moveModel, duplicateModel, batchDeleteModels } = modelActions;
const { fetchUserDir, removeFromDir } = userActions;
const { clearEnclosureState } = enclosureActions;
const { resetResults } = resultsActions;
const { clearUpgradeData } = upgradeActions;

const MainDashboard = ({
    name,
    fetchUserDir,
    modelDir,
    modelDirLoading,
    modelLoading,
    selectModel,
    deleteModel,
    duplicateModel,
    moveModel,
    batchDeleteModels
}) => {
    const { pathname } = useLocation();
    const history = useHistory();

    const path = pathname.split("/").filter((str) => ["", "dashboard"].includes(str) === false) || [];
    const currentFolderId = path[path.length - 1] || null;

    const { models: singleModels = {}, folders = {} } = modelDir;

    const filteredFolders = !isEmpty(folders)
        ? Object.entries(folders)
            .filter(([key, { parentFolderId }]) => parentFolderId === currentFolderId)
            .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
            .map(([key, el]) => [key, { ...el, type: "folder" }])
        : [];

    const filteredModels = !isEmpty(singleModels)
        ? Object.entries(singleModels)
            .filter(([key, { parentFolderId }]) =>
                parentFolderId ? parentFolderId === currentFolderId : currentFolderId ? false : true
            )
            .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
            .map(([key, el]) => [key, { ...el, type: "model" }])
        : [];

    const allFoldersModels = [...filteredFolders, ...filteredModels];

    const [firstName = ""] = name.split(" ");

    const emptyFolderDialog = !modelDirLoading && isEmpty(filteredModels) && isEmpty(filteredFolders) && currentFolderId !== null && (
        <CreateFirstModel />
    );

    return (
        <DashboardLayout 
            dashboardType="modelDir"
            rootDir={"Model Directory"}
            rootPath={`/dashboard`}
            dir={{items: singleModels, folders}}
            fetchDir={fetchUserDir}
            dirLoading={modelDirLoading}
            itemType={"model"}
            allFoldersItems={allFoldersModels}
            itemLoading={modelLoading}
            selectItem={selectModel}
            deleteItem={deleteModel}
            batchDeleteItems={batchDeleteModels}
            duplicateItem={duplicateModel}
            moveItem={moveModel}
            emptyFolderDialog={emptyFolderDialog}
            className={classes.spaceForHeader}
        >
            <div className={classes.dashboardHead} style={{ backgroundImage: `url(${dashboardTemp})` }}>
                <img src={HouseImage} alt="House drawing" />
                <div className={classes.text}>
                    <h1>Hi {firstName},</h1>
                    <p>Welcome to your Volta SNAP dashboard</p>
                    <Button
                        icon={AddIcon}
                        type="white"
                        large
                        onClick={() => history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`)}
                    >
                        Create New Model
                    </Button>
                </div>
            </div>

            {modelDirLoading && <Loading className={classes.loading} message="Loading House Models" />}
            {!modelDirLoading && isEmpty(singleModels) && isEmpty(folders) && (
                <div className={classes.dashboardEmpty}>
                    {/* Empty directory */}
                    <SectionHeading
                        title="Looks like you are a new user!"
                        subtitle="Get started by uploading your code library or diving straight in and creating your first house model."
                        className={classes.emptyHeader}
                    />
                    <div className={classes.boxes}>
                        <div className={classes.box}>
                            <CodeLibIcon />
                            <h3>Upload your Code Library</h3>
                            <p>
                                Long-time HOT2000 user? Upload your code library to bring Volta SNAP up to speed with
                                all your favourite component assemblies.
                            </p>
                            <Button onClick={() => history.push("/code-library")}>Upload Your Code Library</Button>
                        </div>
                        <div className={classes.box}>
                            <HouseIcon />
                            {/* in this folder */}
                            <h3>Create your first House Model </h3>
                            <p>
                                Get started on your first model! Have your building’s construction PDFs ready to upload
                                and get ready to start drawing!
                            </p>
                            <Button onClick={() => history.push("/create")}>Create New Model</Button>
                        </div>
                    </div>
                </div>
            )}
        </DashboardLayout>
    );
}

const mapStateToProps = createStructuredSelector({
    name: selectUserName,
    modelDir: selectUserModelDir,
    modelDirLoading: selectUserModelDirLoading,
    modelLoading: selectModelLoading
});

const mapDispatchToProps = (dispatch, { history }) => ({
    // clearModelData: () => dispatch(clearModelData()), 
    fetchUserDir: (uid, skipLoading) => dispatch(fetchUserDir(uid, skipLoading)),
    selectModel: async (modelId) => {
        await Promise.all([
            dispatch(clearModelData()),
            dispatch(clearDrawingData()),
            dispatch(resetResults()),
            dispatch(clearUpgradeData()),
            dispatch(clearEnclosureState()),
            dispatch(setExportErrors([])),
            dispatch(modelLoading()),
            dispatch(fetchModelData(modelId)),
            dispatch(fetchDrawingData(modelId)),
        ]).then(() => history.push(`/models/${modelId}`));
    },
    deleteModel: async ({ modelId, uid }) => {
        await dispatch(deleteModel({ modelId, uid }));
        return dispatch(removeFromDir(modelId));
    },
    moveModel: async (modelId, uid, folderId, skipLoading) => {
        return dispatch(moveModel(modelId, uid, folderId, skipLoading));
    },
    duplicateModel: async (modelId, uid, folderId, duplicateName) => {
        return dispatch(duplicateModel(modelId, uid, folderId, duplicateName));
    },
    batchDeleteModels: async (modelsToDeleteIds, uid) => dispatch(batchDeleteModels(modelsToDeleteIds, uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);