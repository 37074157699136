import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { updateChecklistItems } from "features/Model/ProjectPathChecklist/_ducks/actions";
import { addImageData } from "store/imageGallery/thunk";

import { selectUserUid } from "store/users/selectors";

import { imageRead } from "features/Model/ImageGallery/utils/functions";

import { storageRef } from "_firebase";

import UploadFile from "components/UploadFile";

import uploadSvg from "assets/images/icons/uploadSvg.svg";

import classes from "./styles.module.scss";
import Subtitle from "components/ChbaChecklist/Subtitle";

const fileTypeMap = {
    "vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "vnd.ms-excel.sheet.macroEnabled.12": "xlsm",
    "vnd.ms-excel.sheet.macroenabled.12": "xlsm",
    "vnd.ms-excel": "xls",
};

const preConstructionStages = [
    "1a.homeownerInterest",
    "1b.idealCandidateSite",
    "2.preRenoEnergyUsage",
    "2.preRetrofitTestingInspection",
    "2.radonTest",
    "2.identifyKeyOpportunitiesChallenges",
    "2.homeAppraisal",
    "3.reviewOptimizationOptions",
    "3.proposedRenewableSolutionPlan",
    "3.heatLossGainRoombyRoom",
    "3.materialCarbonEmissionsEstimator",
    "4.initalPathNzOptimization",
    "4.createEstConstructionTimeline",
    "4.prepareNextStepsIdpNotes",
    "4.finalizePathToNzeroSpecs",
];

const midConstructionStages = ["6.midConstructionBlowerTest"];

const postConstructionStages = [
    "7.ersFinalInspectionTestingProtocol",
    "7.additionalNzeroRenoInspectionProtocol",
    "7.postCaseStudyPhotos",
    "13.postRetrofitUtilityConsumptionCost",
];

const DragAndDropField = ({
    allowedFileTypes = "",
    checkListFiles = [],
    updateChecklistItems,
    name = "",
    modelId,
    uid,
    withForm = false,
    setShowPreview,
    handleSave,
    addImageData,
    projectPathSection,
}) => {
    const [fileDelete, setFileDelete] = useState({ index: null, isDeleting: false });
    const [isUploading, setIsUploading] = useState(false); // check if any file is uploading
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [dropError, setDropError] = useState(null);

    const ref = useRef(null);

    const allowedFileTypesStr = allowedFileTypes.map((file) => `.${file}`).join(",");

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("dragover", handleDragOver);
            // ref.current.addEventListener("drop", handleDrop);
        }

        return () => {
            ref.current.removeEventListener("dragover", handleDragOver);
            // ref.current.removeEventListener("drop", );
        };
    }, [checkListFiles]);

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.target !== ref.current) {
            setIsDraggingOver(true);
        }
    };

    const handleFileUpload = async (event, isDropdown) => {
        event.preventDefault();

        setDropError(null);
        setIsDraggingOver(false);
        setIsUploading(true);

        document.body.style.cursor = "progress";

        // Check if files are dropped or selected
        const { files } = isDropdown ? event.dataTransfer : event.target;

        if (!files && files.length === 0) {
            setIsUploading(false);
            document.body.style.cursor = "default";

            return;
        }

        const allFilesNames = checkListFiles.map((file) => file.name);

        // Check if any file is dropped or selected
        if (files && files.length) {
            const droppedFiles = Array.from(files);

            const updatedFiles = await Promise.all(
                droppedFiles.map((file) =>
                    imageRead(file).then(() => {
                        const splittedName = file.name.split(".");

                        const fileName = `${splittedName.splice(0, splittedName.length - 1).join(" ")}${
                            allFilesNames.includes(file.name)
                                ? splittedName.splice(0, splittedName.length - 1).join(" ") +
                                  `(copy)-${new Date().getTime()}.${splittedName[splittedName.length - 1]}`
                                : splittedName.splice(0, splittedName.length - 1).join(" ") +
                                  `-${new Date().getTime()}.${splittedName[splittedName.length - 1]}`
                        }`;

                        return {
                            id: uuidv4(),
                            name: fileName,
                            type: file.type.split("/")[0],
                            fileType: file.type,
                            isUploading: true,
                            progress: 0,
                            objectUrl: URL.createObjectURL(file),
                            fileToUpload: file,
                            isDeleting: false,
                            size: file.size,
                            metadata: !isEmpty(file.exifdata)
                                ? {
                                      dateTimeOriginal: file.exifdata.DateTimeOriginal,
                                      orientation: file.exifdata.Orientation,
                                  }
                                : null,
                        };
                    })
                )
            );

            // Copy the existing files
            const copiedFiles = JSON.parse(JSON.stringify(checkListFiles));

            // Check if the file type is allowed
            const checkTypes = updatedFiles.map((file) => {
                return fileTypeMap[file.fileType.split("/")[1]] || file.fileType.split("/")[1];
            });

            // Check if the file type is allowed
            if (!checkTypes.every((type) => allowedFileTypes.includes(type))) {
                setDropError("Invalid file type");

                setIsUploading(false);
                document.body.style.cursor = "default";

                setTimeout(() => {
                    setDropError(null);
                }, 3000);

                return;
            }

            // Update the checklist items
            updateChecklistItems({ path: name, value: [...copiedFiles, ...updatedFiles] });

            const promises = [];

            // Upload the files
            for (let i = 0; i < updatedFiles.length; i++) {
                const file = updatedFiles[i];

                const { fileToUpload, name: fileName, type } = file;

                // Create a storage reference
                const filePath =
                    type === "image"
                        ? `${uid}/${modelId}/modelImages/${file.id}`
                        : `${uid}/${modelId}/${fileName.split(".")[0]}/${fileName}`;

                const uploadTask = storageRef.child(filePath).put(fileToUpload);

                // Add the upload task to the promises array
                promises.push(uploadTask);

                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                        updatedFiles[i].progress = progress;

                        updateChecklistItems({ path: name, value: [...copiedFiles, ...updatedFiles] });
                    },
                    (error) => {
                        console.log("error", error);
                    },
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            updatedFiles[i].progress = 100;
                            updatedFiles[i].isUploading = false;
                            updatedFiles[i].fileUrl = downloadURL;
                            updatedFiles[i].fileToUpload = null;
                            updatedFiles[i].objectUrl = null;

                            updateChecklistItems({ path: name, value: [...copiedFiles, ...updatedFiles] });

                            if (type === "image") {
                                updatedFiles[i].uploadedDate = new Date().getTime() / 1000;
                                updatedFiles[i].tags = [];
                                updatedFiles[i].notes = "";
                                updatedFiles[i].status = "";
                                updatedFiles[i].isFromCheckList = true;
                                updatedFiles[i].checkListPath = name;
                                updatedFiles[i].status = preConstructionStages.includes(projectPathSection)
                                    ? "pre_construction"
                                    : midConstructionStages.includes(projectPathSection)
                                    ? "mid_construction"
                                    : postConstructionStages.includes(projectPathSection)
                                    ? "post_construction"
                                    : "";

                                addImageData(modelId, uid, updatedFiles[i]);
                            }
                        });
                    }
                );
            }

            // Wait for all the uploads to complete
            Promise.all(promises)
                .then((tasks) => {
                    setIsUploading(false);
                    handleSave();
                    document.body.style.cursor = "default";
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }
    };

    // Delete the file
    const handleDelete = (event, fileName, index, type) => {
        event.preventDefault();

        // Create a storage reference
        const deleteRef =
            type === "image"
                ? storageRef.child(`${uid}/${modelId}/modelImages/${fileName}`)
                : storageRef.child(`${uid}/${modelId}/${fileName.split(".")[0]}/${fileName}`);

        const updatedFiles = [...checkListFiles];

        setFileDelete({ index, isDeleting: true });

        // Delete the file
        deleteRef
            .delete()
            .then(() => {
                //Successfully deleted
                updatedFiles.splice(index, 1);

                updateChecklistItems({ path: name, value: updatedFiles });
            })
            .then(() => {
                handleSave();
            })
            .finally(() => {
                setFileDelete({ index: null, isDeleting: false });
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const fileType = allowedFileTypes[0] === "pdf" ? "document" : "image";

    return (
        <>
            <div style={{ marginTop: !withForm ? "0.5rem" : "" }}>
                <Subtitle>Upload {fileType === "image" ? "Photo(s)" : "Document(s)"}</Subtitle>
            </div>
            <div
                className={classes.dragAndDropFieldContainer}
                style={{
                    // marginTop: !withForm ? "0.5rem" : "",
                    pointerEvents: isUploading ? "none" : "auto",
                    cursor: isUploading ? "progress" : "auto",
                    // marginBottom: checkListFiles.length === 0 ? "7px" : "",
                }}
            >
                <div
                    ref={ref}
                    className={`${classes.dragAndDropFieldWrapper}`}
                    style={{
                        border: dropError
                            ? "1px dashed #c61717"
                            : !isDraggingOver
                            ? "1px dashed #62BCF8"
                            : " 1px solid #62BCF8",
                        background: dropError ? "#fff0f0 !important" : "transparent",
                    }}
                    onDrop={(event) => handleFileUpload(event, true)}
                >
                    <input
                        className={`${classes.dragAndDropInput}`}
                        type="file"
                        name="files"
                        accept={allowedFileTypesStr}
                        multiple
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                        onChange={(event) => handleFileUpload(event, false)}
                        disabled={fileDelete.isDeleting || isUploading}
                    />
                    {dropError ? (
                        <div
                            className={`${classes.dragAndDropTextWrapper}`}
                            style={{ color: dropError ? "#c61717" : "#3B3B3B" }}
                        >
                            <p>{`${dropError}, only ${allowedFileTypes.map((cat) => `.${cat} `)}`}</p>
                        </div>
                    ) : (
                        <div className={`${classes.dragAndDropTextWrapper}`}>
                            <img src={uploadSvg} alt="Upload icon" />
                            <p>
                                Drag and drop or <span style={{ color: "#0049C6" }}>browse files</span>
                            </p>
                        </div>
                    )}
                    {!dropError && (
                        <span className={`${classes.dragAndDropAllowedCategories}`}>
                            ({allowedFileTypes.map((cat) => `.${cat} `)})
                        </span>
                    )}
                </div>
                {checkListFiles.length > 0 && (
                    <div className={classes.dragAndDropUploadFiles}>
                        {checkListFiles.map(
                            ({ id, name, type, isUploading, progress, fileType, objectUrl, fileUrl }, index) => (
                                <UploadFile
                                    key={index}
                                    fileName={name}
                                    progress={progress}
                                    fileType={fileType}
                                    isUploading={isUploading}
                                    type={type}
                                    objectUrl={objectUrl}
                                    fileUrl={fileUrl}
                                    handleDelete={(event) =>
                                        handleDelete(event, type === "image" ? id : name, index, type)
                                    }
                                    isDeleting={fileDelete.index === index && fileDelete.isDeleting}
                                    isGeneralDeleting={fileDelete.isDeleting}
                                    setShowPreview={setShowPreview}
                                    checkListFiles={checkListFiles}
                                />
                            )
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
});

const mapDispatchToProps = (dispatch) => ({
    updateChecklistItems: (payload) => dispatch(updateChecklistItems(payload)),
    addImageData: (modelId, uid, imageToAdd) => dispatch(addImageData(modelId, uid, imageToAdd)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DragAndDropField);
