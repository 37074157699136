import React, { useCallback, useEffect, useState } from "react";
import { MODEL_COLL } from "_firebase";
import globalStyles from "components/globalStyles.module.scss";
import classes from "./style.module.scss";
import Button from "components/Button";
import Drawer from "components/Drawer";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputFile from "components/Input/FileUpload";
import Checkbox from "components/Input/Checkbox";
import { isEmpty, isEqual } from "lodash";
import NewBuildBatchEcms from "./NewBuildBatchEcms";
import RetrofitBatchEcms from "./RetrofitBatchEcms";
import TableauNewBuildBatchEcms from "./TableauNewBuildBatchEcms";

const initCbatEcmInputMap = {
    ["Opt-FloorHeaderIntIns"]: {
        "NBC_RSI2.97_int": true,
    },
    ["Opt-AboveGradeWall"]: {
        "NC_R-16(eff)_2x6-16inOC_R19-batt_poly_vb": true,
        "NC_R-22(eff)_2x6-16inOC_R22-batt+1inXPS_poly_vb": true,
        "NC_R-26(eff)_2x6-16inOC_R19-batt+2inXPS_poly_vb": true,
        "NC_R-30(eff)_2x6-16inOC_R24-batt+3inMineralWool_poly_vb": true,
        "NC_R-40(eff)_2x6-16inOC_R24-batt+4.5inXPS_poly_vb": true,
    },
    ["Opt-AtticCeilings"]: {
        "CeilR40-Opt-AtticCeilings": true,
        "CeilR50-Opt-AtticCeilings": true,
        "CeilR60-Opt-AtticCeilings": true,
        "CeilR70-Opt-AtticCeilings": true,
        "CeilR80-Opt-AtticCeilings": true,
    },
    ["Opt-FlatCeilings"]: {
        "CeilR40-Opt-FlatCeilings": true,
    },
    ["Opt-CathCeilings"]: {
        "CeilR40-Opt-CathCeilings": true,
    },
    ["Opt-FoundationSlabBelowGrade"]: {
        "uninsulated-Opt-FoundationSlabBelowGrade": true,
        xps2inEffR10: true,
        xps4inEffR20: true,
    },
    ["Opt-FoundationWallIntIns"]: {
        WoodFrameEffR15: true,
    },
    ["Opt-FoundationWallExtIns"]: {
        "uninsulated-Opt-FoundationWallExtIns": true,
        "xps1.5inEffR7.5": true,
        "xps2.5inEffR12.5": true,
    },
    ["Opt-FoundationSlabOnGrade"]: {
        "NBC_936_2.84RSI": true,
    },
    ["Opt-ExposedFloor"]: {
        "NBC_936_5.02RSI": true,
    },
    ["Opt-Windows"]: {
        "NC-3g-LG-u1.08": true,
        "NC-2g-MG-u1.65": true,
        "NC-2g-LG-u1.65": true,
        "NC-3g-MG-u1.08": true,
    },
    ["Opt-ACH"]: {
        "New-Const-air_seal_to_3.50_ach": true,
        "New-Const-air_seal_to_2.50_ach": true,
        "New-Const-air_seal_to_1.50_ach": true,
        "New-Const-air_seal_to_0.60_ach": true,
        "New-Const-air_seal_to_1.00_ach": true,
    },
    ["Opt-DHWSystem"]: {
        "gas_storagetank_w/powervent_ef0.67": true,
        GasInstantaneous: true,
        HPHotWater: true,
        "elec_storage_ef0.89": true,
    },
    ["Opt-DWHR"]: {
        "None;lpDWHR_00_X": true,
        "DWHR-eff-55": true,
    },
    ["Opt-VentSystem"]: {
        HRV_sre_67: true,
    },
    ["Opt-Heating-Cooling"]: {
        "elec-baseboard+AC": true,
        "gas-furnace-ecm+AC": true,
        CCASHP: true,
        ASHP: true,
    },
    ["Opt-WindowDistribution"]: {
        "None;id-16_X": true,
    },
};

const initLeepRetrofitEcmInputMap = {
    ["Opt-FloorHeaderIntIns"]: { "None;Env-X": true },
    ["Opt-AboveGradeWall"]: {
        "None;Env-2NC": true,
        "wallAddUSpec-R7.5": false,
        "wallAddUSpec-R10": true,
        "wallAddUSpec-R17.5": false,
        "wallAddUSpec-R20": true,
        "wallAddUSpec-R27.5": false,
        "wallAddUSpec-R30": true,
        "wallAddUSpec-R37.5": false,
    },
    ["Opt-AtticCeilings"]: {
        "None;Env-1NC": true,
        "atticAddUSpec-R20": true,
        "atticAddUSpec-R30": true,
        "atticAddUSpec-R40": true,
    },
    ["Opt-FlatCeilings"]: { "None;Env-X": true },
    ["Opt-CathCeilings"]: { "None;Env-X": true },
    ["Opt-FoundationSlabBelowGrade"]: { "None;Env-X": true },
    ["Opt-FoundationWallIntIns"]: {
        "None;Env-3NC": true,
        "fndWallAddUSpec-R10": true,
        "fndWallAddUSpec-R17": false,
        "fndWallAddUSpec-R20": false,
        "fndWallAddUSpec-R30": false,
    },
    ["Opt-FoundationWallExtIns"]: { "None;Env-X": true },
    ["Opt-FoundationSlabOnGrade"]: { "None;Env-X": true },
    ["Opt-ExposedFloor"]: { "None;Env-X": true },
    ["Opt-Windows"]: {
        "None;Env-4NC": true,
        "2g-MG-u1.6": true,
        "2g-LG-u1.6": true,
        "3g-MG-u1.05": false,
        "3g-LG-u1.05": false,
        "3g-MG-u0.8": false,
        "3g-LG-u0.8": false,
    },
    ["Opt-ACH"]: {
        "None;Env-5NC": true,
        "airSealTo-2.5": false,
        "airSealTo-1.5": false,
        "airTightnessPercent-25": true,
        "airTightnessPercent-50": true,
        "airTightnessPercent-75": true,
    },
    ["Opt-DHWSystem"]: {
        "None;Mec-3NC": true,
        "dhwGasStorage-EF0.67": true,
        "dhwGasInst-EF0.94": true,
        "dhwElecStorage-EF0.92": true,
        "dhwHPWH-EF3.5": true,
    },
    ["Opt-DWHR"]: { "None;Mec-4NC": true, "dwhr-55eff": true },
    ["Opt-VentSystem"]: { "None;Mec-2NC": true, "hrv-SRE60": true, "hrv-SRE78": true },
    ["Opt-Heating-Cooling"]: {
        "None;Mec-1NC": true,
        "condGasFurnace-95eff": true,
        "hybridHeatExFurnace-ASHP2.5COP": true,
        "hybridHeatCondFurnace-ASHP2.5COP": true,
        "elecHeating-ASHP2.5COP": true,
        "elecHeating-CCASHP3.8COP": true,
    },
    ["Opt-WindowDistribution"]: { "None;id-16_X": true },
};

const initLeepNewConstructionEcmInputMap = {
    ["Opt-FloorHeaderIntIns"]: { "None;Env-1X": true },
    ["Opt-AboveGradeWall"]: {
        "NC_R-17(eff)": true,
        "NC_R-21(eff)": true,
        "NC_R-26(eff)": true,
        "NC_R-30(eff)": false,
        "NC_R-40(eff)": false,
    },
    ["Opt-AtticCeilings"]: {
        "CeilR40-Opt-AtticCeilings": true,
        "CeilR50-Opt-AtticCeilings": true,
        "CeilR60-Opt-AtticCeilings": true,
        "CeilR70-Opt-AtticCeilings": false,
        "CeilR80-Opt-AtticCeilings": false,
    },
    ["Opt-FlatCeilings"]: { "None;Env-2X": true },
    ["Opt-CathCeilings"]: { "None;Env-3X": true },
    ["Opt-FoundationSlabBelowGrade"]: {
        "uninsulated-Opt-FoundationSlabBelowGrade": true,
        slabBelowGradeEffR10: true,
        slabBelowGradeEffR20: false,
    },
    ["Opt-FoundationWallIntIns"]: {
        FoundationWallEffR15: true,
        FoundationWallEffR21: true,
        FoundationWallEffR28: false,
    },
    ["Opt-FoundationWallExtIns"]: { "uninsulated-Opt-FoundationWallExtIns": true },
    ["Opt-FoundationSlabOnGrade"]: {
        slabOnGradeEffR10: true,
        slabOnGradeEffR20: true,
        slabOnGradeEffR30: false,
    },
    ["Opt-ExposedFloor"]: { "None;Env-7X": true },
    ["Opt-Windows"]: {
        "2g-MG-u1.6": true,
        "2g-LG-u1.6": true,
        "2g-MG-u1.2": false,
        "2g-LG-u1.2": false,
        "3g-MG-u1.05": true,
        "3g-LG-u1.05": true,
        "3g-MG-u0.8": false,
        "3g-LG-u0.8": false,
    },
    ["Opt-ACH"]: {
        "airSealTo-3.5": true,
        "airSealTo-3.0": true,
        "airSealTo-2.5": true,
        "airSealTo-2.0": false,
        "airSealTo-1.5": false,
        "airSealTo-1.0": false,
        "airSealTo-0.6": false,
    },
    ["Opt-DHWSystem"]: {
        "dhwGasStorage-EF0.67": false,
        "dhwGasStorage-EF0.80": false,
        "dhwGasInst-EF0.95": true,
        "dhwElecStorage-EF0.92": true,
        "dhwHPWH-EF3.5": true,
    },
    ["Opt-DWHR"]: { "None;Mec_4a_X": true, "dwhr-42eff": true, "dwhr-55eff": false },
    ["Opt-VentSystem"]: { "hrv-SRE60": true, "hrv-SRE75": true },
    ["Opt-Heating-Cooling"]: {
        "condGasFurnace95eff-AC": true,
        "hybridHeatCondFurnace96eff-ASHP2.5COP": true,
        "elec-baseboard+AC": true,
        "elecHeating-ASHP2.5COP": true,
        "elecHeating-CCASHP3.8COP": true,
        "elecHeating-GSHP": true,
        "combo0.8EFTank": false,
        "p9Combo0.9TPF": false,
        "combo0.95EFTankless": false,
        "p9Combo0.96TPF": false,
    },
    ["Opt-WindowDistribution"]: { "None;id-16_X": true },
};

const getBatchSize = (ecmInputMap, setDisabledFields) => {
    const totalCount = Object.keys(ecmInputMap).reduce((product, catKey) => {
        const ecmsInCategory = Object.values(ecmInputMap[catKey]).reduce((n, val) => n + val, 0);

        if (ecmsInCategory === 1) {
            const singleEcm = Object.keys(ecmInputMap[catKey]).find((ecmKey) => ecmInputMap[catKey][ecmKey]);
            setDisabledFields((prevState) => ({ ...prevState, [singleEcm]: true }));
        } else {
            setDisabledFields((prevState) => ({
                ...prevState,
                ...Object.keys(ecmInputMap[catKey]).reduce(
                    (cache, curr) => ({
                        ...cache,
                        [curr]: false,
                    }),
                    {}
                ),
            }));
        }

        return product * (ecmsInCategory || 1);
    }, 1);

    return totalCount;
};

const initParams = {
    mirrorAtticCbatEcms: {
        CathCeilings: false,
        FlatCeilings: false,
    },
};

const NewBatchDrawer = React.memo(
    ({
        isOpen = false,
        toggleOpen,
        handleBatchSubmit = () => {},
        // invalid=false,
    }) => {
        const [validFile, setValidFile] = useState(false);
        const [cbatToken, setCbatToken] = useState("");
        const [batchSize, setBatchSize] = useState(144000);
        const [error, setError] = useState("");
        const [fileData, setFileData] = useState({});
        const [uploadedModelId, setModelId] = useState("");
        const [isUploading, setIsUploading] = useState(false);
        const [ecmInputMap, setEcmInputMap] = useState(initCbatEcmInputMap);
        const [retrofitEcmInputMap, setRetrofitEcmInputMap] = useState(initLeepRetrofitEcmInputMap);
        const [leepNewConstEcmInputMap, setLeepNewConstEcmInputMap] = useState(initLeepNewConstructionEcmInputMap);
        const [buildType, setBuildType] = useState("cbat");
        const [params, setParams] = useState(initParams);

        const [disabledFields, setDisabledFields] = useState({});
        const [disabledRetrofitFields, setDisabledRetrofitFields] = useState({});
        const [disabledLeepNewConstFields, setDisabledLeepNewConstFields] = useState({});

        const handleClose = useCallback(() => {
            setValidFile(false);
            setCbatToken("");
            setBatchSize(144000);
            setFileData({});
            setError("");
            setModelId("");
            setIsUploading(false);
            toggleOpen(false);
            setParams(initParams);
        }, [toggleOpen]);

        useEffect(() => {
            //Update batch size based on ECM map
            const selectedInputMap = {
                cbat: ecmInputMap,
                lret: retrofitEcmInputMap,
                lnco: leepNewConstEcmInputMap,
            }[buildType];

            const disableFieldsFunc = {
                cbat: setDisabledFields,
                lret: setDisabledRetrofitFields,
                lnco: setDisabledLeepNewConstFields,
            }[buildType];

            const totalCount = getBatchSize(selectedInputMap, disableFieldsFunc);
            setBatchSize(totalCount);
        }, [ecmInputMap, retrofitEcmInputMap, leepNewConstEcmInputMap, buildType]);

        const handleBuildTypeChange = (newBuildType) => {
            const selectedInputMap = {
                cbat: ecmInputMap,
                lret: retrofitEcmInputMap,
                lnco: leepNewConstEcmInputMap,
            }[newBuildType];

            const disableFieldsFunc = {
                cbat: setDisabledFields,
                lret: setDisabledRetrofitFields,
                lnco: setDisabledLeepNewConstFields,
            }[newBuildType];

            const totalCount = getBatchSize(selectedInputMap, disableFieldsFunc);
            setBatchSize(totalCount);

            setBuildType(newBuildType);
        };

        const handleUpload = useCallback(async (data) => {
            setError("");
            setValidFile(false);

            if (!data) {
                return;
            }

            const { modelData = {}, modelDetails = {}, modelPath = "", containsResults = false } = data;
            const modelId = modelPath.split(`${MODEL_COLL}/`)[1];
            setValidFile(containsResults);

            setModelId(modelId);
            setFileData(data);
        }, []);

        const handleSubmit = async () => {
            setIsUploading(true);
            setError("");

            const activeEcmInputMap = {
                cbat: ecmInputMap,
                lret: retrofitEcmInputMap,
                lnco: leepNewConstEcmInputMap,
            }[buildType];

            const response = await handleBatchSubmit({
                batchSize,
                modelId: uploadedModelId,
                cbatToken,
                ecmInputMap: activeEcmInputMap,
                buildType,
                params: buildType === "lret" ? params : initParams, //only lret looks to params
            });

            if (isEmpty(response.err)) {
                setIsUploading(false);
                toggleOpen(false);
            } else {
                setIsUploading(false);
                const { message: statusMessage = "Request failed with status code 500" } = response.err.toJSON();
                const message = response?.err?.response?.data?.message || statusMessage;

                setError(message);
            }
        };

        const validBatchSize = batchSize > 0 && batchSize <= 150000;

        return (
            <Drawer
                open={isOpen}
                title="Create New Batch Simulation"
                subtitle="Upload your .h2k file to run a parametric analysis"
                close={handleClose}
                classes={{
                    paperClass: classes.batchDrawerPaper,
                }}
                width={1024}
            >
                <div className={classes.contentWrapper}>
                    <div className={classes.mainContent}>
                        <h3 style={{ marginBottom: "0.5rem" }}>File Upload and Token</h3>
                        <InputRow>
                            <InputFile
                                name="fileUpload"
                                id="fileUpload"
                                label="File Upload"
                                text="Please begin by uploading your .h2k file"
                                handleUpload={handleUpload}
                                accept=".h2k,.xml"
                            />
                        </InputRow>

                        {!validFile && !isEmpty(fileData) && (
                            <div className={classes.invalidFile}>
                                <p style={{ fontSize: "0.875rem" }}>
                                    The file you have uploaded does not contain any results. Please upload another file
                                    with a complete set of results, that does not generate any errors when simulating in
                                    HOT2000.
                                </p>
                            </div>
                        )}

                        <InputRow gridTemplate="1fr 1fr 1fr">
                            <Input
                                label="Token"
                                input={{
                                    value: cbatToken,
                                    onChange: setCbatToken,
                                }}
                            />
                        </InputRow>
                    </div>
                    <div className={classes.batchTypeContainer}>
                        <div
                            className={`${classes.batchTypeSelector} ${
                                buildType === "cbat" && classes.selectedBuildType
                            }`}
                            onClick={() => handleBuildTypeChange("cbat")}
                        >
                            <span>New Build (CBAT)</span>
                        </div>
                        <div
                            className={`${classes.batchTypeSelector} ${
                                buildType === "lnco" && classes.selectedBuildType
                            }`}
                            onClick={() => handleBuildTypeChange("lnco")}
                        >
                            <span>New Build (Tableau)</span>
                        </div>
                        <div
                            className={`${classes.batchTypeSelector} ${
                                buildType === "lret" && classes.selectedBuildType
                            }`}
                            onClick={() => handleBuildTypeChange("lret")}
                        >
                            <span>Retrofits (Tableau)</span>
                        </div>
                    </div>

                    <h3 style={{ marginBottom: "0.5rem" }}>ECM Selection</h3>
                    <div className={classes.textWrapper}>
                        <p>
                            Disable any ECMs that are not of interest. All categories must have at least one ECM
                            enabled.
                        </p>
                    </div>

                    {buildType === "cbat" ? (
                        <NewBuildBatchEcms
                            ecmInputMap={ecmInputMap}
                            setEcmInputMap={setEcmInputMap}
                            disabledFields={disabledFields}
                        />
                    ) : buildType === "lret" ? (
                        <RetrofitBatchEcms
                            ecmInputMap={retrofitEcmInputMap}
                            setEcmInputMap={setRetrofitEcmInputMap}
                            disabledFields={disabledRetrofitFields}
                            params={params}
                            setParams={setParams}
                        />
                    ) : (
                        <TableauNewBuildBatchEcms
                            ecmInputMap={leepNewConstEcmInputMap}
                            setEcmInputMap={setLeepNewConstEcmInputMap}
                            disabledFields={disabledLeepNewConstFields}
                        />
                    )}

                    <div className={classes.summaryContainer}>
                        <InputRow gridTemplate="1fr 1fr 1fr">
                            <Input
                                type="number"
                                label="Batch Size (Max 150000)"
                                placeholder={0}
                                decimals={0}
                                disabled={true}
                                input={{
                                    value: batchSize,
                                    onChange: setBatchSize,
                                }}
                            />
                        </InputRow>
                    </div>
                    <div className={classes.errorMsg}>{error && <p>{error}</p>}</div>
                    <div className={`${globalStyles.buttons} ${classes.drawerButtons}`}>
                        <Button large type="hollow" onClick={handleClose} disabled={isUploading}>
                            Cancel
                        </Button>
                        <Button
                            large
                            onClick={handleSubmit}
                            disabled={isUploading || !validFile || !validBatchSize || !cbatToken}
                        >
                            {isUploading ? "Submitting..." : "Submit"}
                        </Button>
                    </div>
                </div>
            </Drawer>
        );
    },
    isEqual
);

export default NewBatchDrawer;
