import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardLayers from "./";
import uDefWindowCodeTemplate from "features/Model/Enclosure/Templates/windowUDef.json";
import { idIfyDate } from "utils";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { updateUpgrade } from "store/upgradeLibrary/actions";
import isEmpty from "lodash/isEmpty";
import { getAllWindowAccessors, getMatchingWindowCodeRefs } from "utils/enclosure/windows";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode } = enclosureActions;

const mapStateToProps = (
    {
        form,
        enclosure: { initCodeValues: { windowUDef = {} } = {} } = {},
        user: { uid },
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    {
        windowAccessor,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        componentType,
        isCodeLibrary = false,
        handleUdefSave,
    }
) => {
    const selector = formValueSelector("uDefCodeWindow");
    const modelSelector = formValueSelector("model");

    const { windowLegacy: windowLegacyLayers = {}, window: windowOverallLayers = {} } =
        selector({ form }, "uDefCode.layers") || {};

    const legacy = Object.keys(windowLegacyLayers).map((key) => ({
        ...windowLegacyLayers[key],
        id: key,
        type: "windowLegacy",
    }));

    const overall = Object.keys(windowOverallLayers).map((key) => ({
        ...windowOverallLayers[key],
        id: key,
        type: "windowOverall",
    }));

    const codeLayers = [...legacy, ...overall];

    const { codeRef: editingCodeRef = null, isLibCode = false } = windowUDef;

    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        componentType,
        initialValues: {
            uDefCode: isEmpty(windowUDef) ? uDefWindowCodeTemplate : windowUDef,
            addToLibrary: isEmpty(windowUDef) ? true : isLibCode,
        },
        codeLayers,
        numLayers: codeLayers.length,
        modelFormChange,
        windowAccessor,
        modelCodes,
        components,
        editingCodeRef,
        isLibCode,
        uid,
        isCodeLibrary,
        isUpgradeComponent: (windowAccessor || "").includes("fields"),
        handleUdefSave,
        currentFormChange,
        currentPackage,
        selectedUpgrade,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        windowAccessor,
        editingCodeRef,
        modelFormChange,
        uid,
        componentType,
        isCodeLibrary,
        handleUdefSave,
        isEditing,
        components,
        modelCodes,
        currentFormChange,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { uDefCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;
    const { label = "", isLibCode } = uDefCode;
    //Close drawer
    handleUdefSave();

    // 1. Create and change code ref
    const newCodeRef = `Window-U-${idIfyDate()}`;
    const forceNewCodeRef = (isLibCode && !isCodeLibrary) || (isEditing && isCodeLibrary) || isEditing;
    const setCodeRef = forceNewCodeRef ? newCodeRef : editingCodeRef || newCodeRef;

    // TODO: 2. Fetch rValue

    // ***************************************
    // 3. If in model, do model things
    // ***************************************
    if (!isCodeLibrary) {
        const code = {
            ...uDefCode,
            codeRef: setCodeRef,
            nominalRValue: 0, // Change when we get value
            rValue: 0, // Change when we get value
            er: 0,
            shgc: 0,
        };

        //Add to upgrade codes if in upgrade library
        if (currentPackage && selectedUpgrade) {
            const currentUpgrade = currentPackage.upgrades[selectedUpgrade];

            dispatch(
                updateUpgrade(selectedUpgrade, {
                    ...currentUpgrade,
                    codes: currentUpgrade
                        ? { ...currentUpgrade.codes, [code.codeRef]: code }
                        : { [code.codeRef]: code },
                })
            );
        }

        modelFormChange(`modelData.codes.${setCodeRef}`, code);

        const fieldValue = {
            codeLabel: label,
            codeRef: setCodeRef,
        };

        // Update model "window type" field in window
        currentFormChange(`${windowAccessor}.windowType`, fieldValue);
        currentFormChange(`${windowAccessor}.windowType_nomRVal`, 0);
        currentFormChange(`${windowAccessor}.windowType_effRVal`, 0);
        currentFormChange(`${windowAccessor}.shgc`, 0);
        currentFormChange(`${windowAccessor}.er`, 0);

        if (setAsModelDefault) {
            const defaultValue = {
                windowType: fieldValue,
                er: 0,
                shgc: 0,
                windowType_nomRVal: 0,
                windowType_effRVal: 0,
            };
            modelFormChange("modelData.defaultCodes.window", defaultValue);
        }

        if (!isEmpty(selectedComponents)) {
            selectedComponents.forEach((path) => {
                modelFormChange(`modelData.components.${path}.windowType`, fieldValue);
                modelFormChange(`modelData.components.${path}.windowType_effRVal`, 0);
                modelFormChange(`modelData.components.${path}.windowType_nomRVal`, 0);
                modelFormChange(`modelData.components.${path}.er`, 0);
                modelFormChange(`modelData.components.${path}.shgc`, 0);
            });
        }

        if (isEditing && isLibCode) {
            const accessorsToUpdate = getMatchingWindowCodeRefs(
                components,
                editingCodeRef,
                windowAccessor.split("modelData.components.")[1]
            );
            if (!isEmpty(accessorsToUpdate)) {
                accessorsToUpdate.forEach((path) => {
                    modelFormChange(`${path}.windowType`, fieldValue);
                    modelFormChange(`${path}.windowType_effRVal`, 0);
                    modelFormChange(`${path}.windowType_nomRVal`, 0);
                    modelFormChange(`${path}.er`, 0);
                    modelFormChange(`${path}.shgc`, 0);
                });
            }
        }

        if (forceNewCodeRef && addToLibrary) {
            //Must delete from modelCodes here at the end, after updating every other codeRef
            // const otherComponentsWithCode =
            //     getMatchingWindowCodeRefs(
            //         components,
            //         editingCodeRef,
            //         windowAccessor.split("modelData.components.")[1]
            //     ).filter((path) => !path.includes(windowAccessor.split("modelData.components.")[1])) || [];
            // if (isEmpty(otherComponentsWithCode) || isLibCode) {
            //     modelFormChange("modelData.codes", {
            //         ...Object.keys(modelCodes).reduce((acc, curr) => {
            //             return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //         }, {}),
            //         [setCodeRef]: code,
            //     });
            // }
            // modelFormChange("modelData.codes", {
            //     ...Object.keys(modelCodes).reduce((acc, curr) => {
            //         return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //     }, {}),
            //     [setCodeRef]: code,
            // });
        }
    }

    // ***************************************
    // 4. If in code library or "add to code library" is selected, do code library things
    // ***************************************
    if (addToLibrary || isCodeLibrary) {
        await dispatch(
            addToCodeLib(
                uid,
                {
                    ...uDefCode,
                    nominalRValue: 0,
                    er: 0,
                    shgc: 0,
                    label,
                    codeType: "User Defined",
                    componentType: componentType,
                    codeRef: setCodeRef,
                },
                componentType,
                setCodeRef
            )
        )
            .then(() => {
                //If we're overwriting an existing code, delete the old one
                if (forceNewCodeRef) {
                    dispatch(deleteCode(uid, editingCodeRef, "Window"));
                }
            })
            .catch(() => {});
    }
    // Clear wall code defaults for next time
    dispatch(setInitCode({}, "windowUDef"));
    // Reset form
    dispatch(reset("uDefCodeWindow"));
};

const mapDispatchToProps = (dispatch) => ({});

const form = reduxForm({
    form: "uDefCodeWindow",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardLayers);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
