import React, { useState, useMemo, useEffect } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getOptions, getBaseUnits } from "utils/fields";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import { StoreyIcon } from "utils/thumbnails/storeys";
import { PlanIcon } from "utils/thumbnails/planShape";
import Geometry from "./Geometry/container";
import Button from "components/Button";
import RotateHouseDialog from "./RotateHouseDialog";
import Details from "./Details";
import { isEqual } from "lodash";
import TabHeader from "components/TabHeader";
import DetailsDrawer from "components/DetailsDrawer";
import RatioIcon from "assets/images/icons/Ratio.svg";
import Storeys from "./Storeys/container";
import { getMinVentRate } from "utils/buildingCode";

import RotateHomeIcon from "assets/images/icons/JSX/RotateHome";

const storeyValidation = getValidation("numStoreys");
const planValidation = getValidation("planShape");
const minVentValidation = getValidation("minBedroomVentilation");
const roomsBedValidation = getValidation("numBedrooms");

const Enclosure = React.memo(
    ({
        storeysId,
        planId,
        change,
        windowWallRatio = 0,
        modelUnits,
        minVentilationValue,
        minVentilationUnits,
        buildingCodeEntered,
        vSA,
        updateSpecifications,
        formModelData,
        facingDirection,
        floorArea,
    }) => {
        const [open, toggleOpen] = useState(false);
        const [isRotateDialogOpen, setIsRotateDialogOpen] = useState(false);

        const handleBedroomChange = (bedrooms) => {
            change(
                "modelData.ventilation.requirements.minBedroomVentilation",
                getMinVentRate(buildingCodeEntered, bedrooms, floorArea)
            );
        };

        return (
            <>
                <TabHeader
                    title="Enclosure Information"
                    description="Information about the construction details of your building."
                    detailsTrigger={() => toggleOpen(true)}
                    detailsText="Enclosure Tab Details"
                    additionalContent={
                        <div className={classes.statsContainer}>
                            <div className={classes.stat}>
                                <img src={RatioIcon} alt="Thermometer and sun" />
                                <div>
                                    {/* {//! Window to Wall Ratio is negative } */}
                                    <span className={classes.name}>Window to Wall Ratio</span>
                                    <span className={classes.value}>
                                        {Number.isNaN(windowWallRatio) ? (0.0).toFixed(2) : windowWallRatio.toFixed(2)}%
                                    </span>
                                </div>
                            </div>
                            <div
                            // className={classes.stat}
                            >
                                <Button
                                    icon={RotateHomeIcon}
                                    type={"lightGrey"}
                                    onClick={() => setIsRotateDialogOpen(true)}
                                >
                                    Rotate Home
                                </Button>
                            </div>
                        </div>
                    }
                />
                <div className={sharedClasses.section}>
                    <DetailsDrawer open={open} close={() => toggleOpen(false)} title="Enclosure Tab Details">
                        <Details />
                        <div className={classes.buttons}>
                            <Button large type="hollow" onClick={() => toggleOpen(false)}>
                                Close
                            </Button>
                        </div>
                        <span></span>
                    </DetailsDrawer>
                    <h3 style={{ marginBottom: "0.75rem" }}>Geometry Summary</h3>
                    <Geometry accessor={"modelData.dimensions"} change={change} />
                    <InputRow gridTemplate="31.85% 31.85% 9.625% 20%">
                        <div className={classes.fieldWithThumbnail}>
                            <SwitchTransition mode="out-in">
                                <CSSTransition
                                    key={storeysId}
                                    classNames={{
                                        enter: classes.imageEnter,
                                        enterActive: classes.imageEnterActive,
                                        exit: classes.imageExit,
                                        exitActive: classes.imageExitActive,
                                    }}
                                    timeout={300}
                                >
                                    <div className={classes.thumbnail}>
                                        <img src={StoreyIcon(storeysId).img} alt={StoreyIcon(storeysId).alt} />
                                    </div>
                                </CSSTransition>
                            </SwitchTransition>

                            <Field
                                component={Select}
                                name="modelData.specifications.numStoreys"
                                options={getOptions({ fieldKey: "numStoreys" })}
                                onChange={(e) => updateSpecifications("numStoreys", e.id, formModelData)}
                                label="Storeys"
                                placeholder="Choose Number of Storeys"
                                validate={storeyValidation}
                                info={
                                    "If the Building Type is set to Multi-unit: one unit, enter the number of storeys occupied by the unit itself. Otherwise, enter the number of storeys in the entire building."
                                }
                            />
                        </div>
                        <div className={classes.fieldWithThumbnail}>
                            <SwitchTransition mode="out-in">
                                <CSSTransition
                                    key={planId}
                                    classNames={{
                                        enter: classes.imageEnter,
                                        enterActive: classes.imageEnterActive,
                                        exit: classes.imageExit,
                                        exitActive: classes.imageExitActive,
                                    }}
                                    timeout={300}
                                >
                                    <div className={classes.thumbnail}>
                                        <img src={PlanIcon(planId).img} alt={PlanIcon(planId).alt} />
                                    </div>
                                </CSSTransition>
                            </SwitchTransition>
                            <Field
                                component={Select}
                                name="modelData.specifications.planShape"
                                options={getOptions({ fieldKey: "planShape" })}
                                onChange={(e) => updateSpecifications("planShape", e.id, formModelData)}
                                label="Plan Shape"
                                placeholder="Choose Plan Shape"
                                validate={planValidation}
                            />
                        </div>
                        <Field
                            component={Input}
                            type="number"
                            name={`modelData.ventilation.rooms.bedrooms`}
                            label="Bedrooms"
                            disabled={false}
                            validate={roomsBedValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("numBedrooms")}
                            nonH2kParam="f280BedroomCount"
                            onChange={(e) => {
                                // setTakeoffChanged(true);
                                handleBedroomChange(e);
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`modelData.ventilation.requirements.minBedroomVentilation`}
                            label="Minimum Ventilation Rate"
                            placeholder={0.0}
                            validate={minVentValidation}
                            decimals={getDecimalPlaces("minBedroomVentilation")}
                            change={change}
                            disabled={true}
                            setValue={minVentilationValue}
                            nonH2kParam="f280MinVentilationRate"
                            units={{
                                base: getBaseUnits("minBedroomVentilation", modelUnits),
                                options: ["L/s", "cfm"],
                                selected: minVentilationUnits,
                                unitType: "airFlow",
                                accessor: "modelData.ventilation.requirements.minBedroomVentilation_u",
                            }}
                        />
                    </InputRow>
                    <Storeys change={change} />
                    <RotateHouseDialog
                        isDialogOpen={isRotateDialogOpen}
                        closeDialog={() => setIsRotateDialogOpen(false)}
                        facingDirection={facingDirection}
                    />
                </div>
            </>
        );
    },
    isEqual
);

export default Enclosure;
