import React from "react";
import { Field } from "redux-form";
import Button from "components/Button";
import classes from "./style.module.scss";
import TripleRadio from "components/Input/TripleRadio";
import Export from "assets/images/icons/JSX/Export";
import netZeroClasses from "../style.module.scss";

const VerificationChecklist = ({ accessor }) => {
    // const input1 = useRef(null);

    return (
        <>
            <div className={classes.topRow}>
                <div className={classes.checklistDescription}>
                    <h3>Technical Requirements Verification Checklist</h3>
                    <p>
                        This checklist shall be filled out upon completion of each Net Zero or Net checkboxHeaders Ready
                        Home by the Qualified Net Zero Energy Advisor with the Qualified Net Zero Renovator.
                    </p>
                </div>
                {/* <Button smallPadding icon={Export} className={netZeroClasses.exportButton}>
                    <span>Export Net Zero Workbook </span>
                </Button> */}
            </div>
            {/* 2.1 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>2.1</p>
                        <p>Compliance with Building Codes and Regulations</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.1.1</p>
                        <p>Comply with local building code or regulation.</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-1.1`}
                            type="checkbox"
                            // setValue={input}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.1.2</p>
                        <p>
                            Comply with the technical requirements of Canada's Energy Efficiency Regulations for
                            regulated products.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-1.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.1.3</p>
                        <p>
                            Equipment and Technologies are commercially available, meet applicable North American
                            Standards, and are suitable for the intended application.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-1.3`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 2.2 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>2.2</p>
                        <p>Proposed House</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.2.1</p>
                        <p>
                            The annual space heating energy consumption (MJ) is at least 33% lower than the NRCan
                            reference house.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-2.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.2.2</p>
                        <p>
                            The space cooling threshold is calculated, and if cooling load exceeds threshold, space
                            cooling energy consumption is included in total annual energy consumption (if an ASHP is
                            used, check "N/A").
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-2.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.2.3</p>
                        <p>
                            When modelled with renewables, net energy consumption of the proposed house is not greater
                            than 0 GJ.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-2.3`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 2.4 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>2.4</p>
                        <p>Energy Modelling</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.4.1</p>
                        <p>
                            0 GJ rating shall be achieved using modelling methods and calculations in conformance with
                            ERS v15, using HOT2000 v11.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-4.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 2.5 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>2.5</p>
                        <p>Renewable and Energy Production Systems</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.5.1</p>
                        <p>
                            Renewable energy generation technologies used comply with the current and applicable version
                            of the relevant industry standard.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-5.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.5.2</p>
                        <p>
                            Inverters and controllers comply with the current and applicable version of the relevant
                            industry standard.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-5.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={`${classes.sectionBody}`}>
                    <div className={classes.checklistInner}>
                        <p>2.5.3</p>
                        <p>
                            Energy monitoring system that provides electricity production and consumption data is
                            installed.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-5.3`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>2.5.4</p>
                        <p>
                            Solar domestic hot water systems comply with the current and applicable version of the
                            relevant industry standard.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.2-5.4`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 3.2 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>3.2</p>
                        <p>Airtightness</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>3.2.1</p>
                        <p>
                            Whole house air leakage is less than or equal to one of the airtightness targets specified
                            in Table 3.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.3-2.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 3.3 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>3.3</p>
                        <p>Opaque Assemblies</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>3.3.1</p>
                        <p>
                            Effective thermal resistance of opaque assemblies are not less than those specified in the
                            Renovations Table.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.3-3.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>3.3.2</p>
                        <p>
                            Eligible insulation materials conform with the requirements listed in{" "}
                            <strong className={classes.boldBlack}>Section 3.3.2.</strong>
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.3-3.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>3.3.3</p>
                        <p>
                            Wall and floor assemblies below or in contact with the ground meet the requirements in
                            <strong className={classes.boldBlack}>Section 3.3.3.</strong>
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.3-3.3`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>3.3.4</p>
                        <p>
                            Thermal resistance in ceilings below attics under sloped roofs is continuous to the
                            outermost edge of the exterior wall excluding the extent imposed by the roof slope and
                            minimum venting clearance.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.3-3.4`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 3.4 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>3.4</p>
                        <p>Fenestration and Doors</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>3.4.1</p>
                        <p>
                            Fenestration systems are ENERGY STAR certified for the climate zone which they are
                            installed, or comply with CAN/CSA-A440.2-14/A440.3-14, or comply with both National
                            Fenestration Rating Council’s NRFC 100 and NRFC 200 (except as provided in{" "}
                            <strong className={classes.boldBlack}>Section 3.4.1.2</strong>). Fenestration systems are
                            constructed with minimum: double glazed low-e glass, inert gas-fill, insulated spacers, and
                            a thermal brake or sash where it is metal.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.3-4.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>3.4.2</p>
                        <p>
                            Exterior door systems are ENERGY STAR certified except where specified in{" "}
                            <strong className={classes.boldBlack}>Section 3.3.2.</strong>
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.3-4.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 4.1 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>4.1</p>
                        <p>Mechanical and Electrical Systems</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.1.2</p>
                        <p>
                            Electrical base loads (Lighting & appliances) are accounted for and off-set as described in{" "}
                            <strong className={classes.boldBlack}>Section 4.1.2.</strong>
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-1.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.1.3</p>
                        <p>
                            Natural gas-fired and propane-fired equipment are equipped with electronic ignition and are
                            independently vented with a sealed vent connected to either a direct-vent system or
                            mechanically-vented system, unless otherwise permitted in{" "}
                            <strong className={classes.boldBlack}>Section 4.3</strong>
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-1.3`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.1.4</p>
                        <p>
                            Oil-fired stoves, fireplaces and water heaters comply with the most current and relevant
                            industry standards (CAN/CSA/ULC) and with local code requirements. Oil-fired stoves,
                            fireplaces and water heaters are of either type: direct-vent system, forced-draft system, or
                            terminally mounted induced-draft power venter.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-1.4`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.1.5</p>
                        <p>
                            Equipment and Technologies are commercially available, meet applicable North American
                            Standards, and are suitable for the intended application.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-1.5`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.1.6</p>
                        <p>
                            Unvented fuel-fired appliances are not permitted as described in{" "}
                            <strong className={classes.boldBlack}>Section 4.1.6.</strong>
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-1.6`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 4.2 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>4.2</p>
                        <p>Heating and Cooling Systems</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.2.1</p>
                        <p>
                            Space heating and space cooling systems are sized in accordance with CSA F280-12.
                            Calculations are preformed outside of HOT2000.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-2.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.2.2</p>
                        <p>Hydronic heating systems comply with CAN/CSA B214-16.</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-2.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.2.3</p>
                        <p>
                            Air-source heat pumps shall be of the type regulated under Canada's Energy Efficiency
                            Regulations.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-2.3`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.2.4</p>
                        <p>Ground or water source energy systems comply with ANSI/CSA C448 SERIES-16.</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-2.4`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.2.5</p>
                        <p>
                            Natural gas and propane fireplaces are direct-vent (sealed) and pilot-on-demand without a
                            standing pilot light.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-2.5`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 4.3 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>4.3</p>
                        <p>Systems Providing both Space and Water Heating</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.3.1</p>
                        <p>
                            Combined space and water heating systems are of the condensing type and comply with CSA
                            P.911.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-3.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.3.2</p>
                        <p>Integrated Mechanical Systems are of the condensing type and comply with CSA P.10-07.</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-3.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 4.4 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>4.4</p>
                        <p>Solid Fuel Burning Appliances</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.4.1</p>
                        <p>
                            Solid fuel burning appliances comply with the requirements of{" "}
                            <strong className={classes.boldBlack}>Section 4.4.</strong> (See Renovation Requirement)
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-4.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 4.5 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>4.5</p>
                        <p>Domestic Hot Water Systems</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.5.1</p>
                        <p>Gas-fired instantaneous water heaters are certified to CAN/CSA P.7-10 (R2015).</p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-5.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 4.6 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>4.6</p>
                        <p>Water Heating Energy Conservation</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.6.1</p>
                        <p>
                            Drain water heat recovery units conform to CSA B55.2-15 and the minimum efficiency is in
                            conformance with CSA B55.1-15.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-6.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 4.7 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>4.7</p>
                        <p>Ventilation Systems</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.7.2</p>
                        <p>
                            The principle ventilation capacity is achieved through either a heat recovery ventilator
                            (HRV) energy recovery ventilator (ERV) or integrated mechanical system (IMS).
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-7.2`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.7.3</p>
                        <p>
                            Ventilation systems are designed in accordance with{" "}
                            <strong className={classes.boldBlack}>Section 4.7.3.</strong>
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-7.3`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.7.4</p>
                        <p>
                            HRV's and ERV's shall be of the type that is regulated under Canada's Energy Efficiency
                            Regulations or ENERGY STAR certified. Integrated HRV air handlers shall be tested to Section
                            8 of CSA P.10-07(R2012) and have a defrost mechanism.
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-7.4`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            {/* 4.8 */}
            <div className={classes.checklistSection}>
                <div className={classes.sectionHeader}>
                    <div className={classes.checklistTitleRow}>
                        <p>4.8</p>
                        <p>Air Distribution Systems</p>
                    </div>
                    <div className={classes.checkboxHeaders}>
                        <p>Renovator</p>
                        <p>EA</p>
                        <p>N/A</p>
                    </div>
                </div>
                <div className={classes.sectionBody}>
                    <div className={classes.checklistInner}>
                        <p>4.8.1</p>
                        <p>
                            Air distributions Systems comply with the requirements of{" "}
                            <strong className={classes.boldBlack}>Section 4.8.</strong> Section 4.8. (See renovations
                            note)
                        </p>
                    </div>
                    <div className={classes.checklistInner}>
                        <Field
                            className={classes.netZeroCheckbox}
                            component={TripleRadio}
                            name={`${accessor}.4-8.1`}
                            type="checkbox"
                            // setValue={}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.topRow}>
                <div></div>
                {/* <Button smallPadding icon={Export} className={netZeroClasses.exportButton}>
                    <span>Export Net Zero Workbook </span>
                </Button> */}
            </div>
        </>
    );
};

export default VerificationChecklist;
