import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import pdfIcon from "assets/images/icons/pdfIcon.svg";
import docFileIcon from "assets/images/icons/docFile.svg";
import spreadSheetIcon from "assets/images/icons/spreadSheet.svg";
import circleRemove from "assets/images/icons/circleRemove.svg";

import classes from "./styles.module.scss";
import FileImagePreview from "components/FileImagePreview";
import CloseIcon from "assets/images/icons/JSX/Close";
import ArrowDown from "assets/images/icons/JSX/arrow-down";

const fileIcon = {
    "application/pdf": pdfIcon,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": spreadSheetIcon,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": docFileIcon,
    "application/vnd.ms-excel.sheet.macroEnabled.12": spreadSheetIcon,
    "application/vnd.ms-excel": spreadSheetIcon,
};

const UploadFile = ({
    fileName,
    progress,
    isUploading,
    fileType,
    type,
    objectUrl,
    fileUrl,
    handleDelete,
    isDeleting,
    isGeneralDeleting,
    checkListFiles,
}) => {
    const location = useLocation();
    const history = useHistory();

    console.log("fileType", fileType);

    const [isOpen, setIsOpen] = useState(false);

    const multipleFiles = checkListFiles.length > 1 && type === "image";

    const [currentFileInd, setCurrentFileInd] = useState(0);

    if (currentFileInd < 0) {
        setCurrentFileInd(checkListFiles.length - 1);
    } else if (currentFileInd > checkListFiles.length - 1) {
        setCurrentFileInd(0);
    }

    const checkFileUrl = () =>
        checkListFiles.map((file, ind) => {
            if (fileUrl === file.fileUrl) {
                setCurrentFileInd(ind);
            }
        });

    return (
        <>
            <FileImagePreview open={isOpen} setIsOpen={setIsOpen}>
                <div
                    className={classes.closeButton}
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    <CloseIcon />
                </div>
                <div className={`${classes.previewContainer} ${type !== "image" && classes.hasPdf}`}>
                    {multipleFiles && (
                        <div
                            className={`${classes.arrowButton} ${classes.left}`}
                            onClick={() => {
                                setCurrentFileInd(currentFileInd - 1);
                            }}
                        >
                            <ArrowDown />
                        </div>
                    )}
                    {type === "image" ? (
                        <img
                            src={checkListFiles[currentFileInd]?.fileUrl || checkListFiles[0].fileUrl}
                            className={classes.image}
                        />
                    ) : (
                        <iframe title="Preview uploaded file" src={fileUrl} width="1000px" height="800px" />
                    )}
                    {multipleFiles && (
                        <div
                            className={`${classes.arrowButton} ${classes.right}`}
                            onClick={() => {
                                setCurrentFileInd(currentFileInd + 1);
                            }}
                        >
                            <ArrowDown style={{ color: "white" }} />
                        </div>
                    )}
                </div>
                {multipleFiles && (
                    <div className={classes.imageIndex}>
                        {currentFileInd + 1} / {checkListFiles.length}
                    </div>
                )}
            </FileImagePreview>
            <div
                className={`${classes.uploadFileContainer} ${
                    isGeneralDeleting || isUploading ? "" : classes.withHover
                }`}
                style={{
                    opacity: isDeleting ? "0.6" : 1,
                    pointerEvents: isGeneralDeleting || isUploading ? "none" : "auto",
                }}
                onClick={(event) => {
                    history.push(`${location.pathname}?file=${fileName}&isImage=${type === "image" ? true : false}`);

                    if (event.target.className !== "deleteButton") {
                        setIsOpen(true);
                        checkFileUrl();
                    }
                }}
            >
                {type === "image" ? (
                    <div style={{ width: "60px", height: "40px", display: "flex", alignItems: "center" }}>
                        <img src={isUploading ? objectUrl : fileUrl} alt="image" style={{ maxHeight: "40px" }} />
                    </div>
                ) : (
                    <img src={fileIcon[fileType]} alt="Icon" />
                )}
                <div className={classes.nameProgressWrapper} style={{ marginTop: isUploading ? "-2px" : 0 }}>
                    <div className={classes.fileNameProgressContainer}>
                        <p className={`${classes.fileName} ${isUploading ? classes.fileNameUploading : ""}`}>
                            {fileName}
                        </p>
                        {isUploading && <p className={classes.progressText}>{progress}%</p>}
                    </div>
                    {isUploading && (
                        <div className={classes.progressContainer}>
                            <div style={{ width: `${progress}%` }} className={classes.progressBar} />
                        </div>
                    )}
                </div>
                <img
                    className="deleteButton"
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                    src={circleRemove}
                    alt="Delete icon"
                    onClick={handleDelete}
                    styles={{ pointerEvents: isGeneralDeleting || isUploading ? "none" : "auto" }}
                />
            </div>
        </>
    );
};

export default UploadFile;
