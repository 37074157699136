import { isEqual } from "lodash";

export const mergeObjects = (main, compare, snapshot) => {
    const result = {};

    const existsIn = (obj, key) => obj && obj.hasOwnProperty(key);
    const isObject = (value) => value && typeof value === "object" && !Array.isArray(value);
    const isArray = (value) => Array.isArray(value);

    const keys = new Set([...Object.keys(main || {}), ...Object.keys(compare || {}), ...Object.keys(snapshot || {})]);

    keys.forEach((key) => {
        if (isObject(main?.[key]) || isObject(compare?.[key]) || isObject(snapshot?.[key])) {
            if (
                !existsIn(main, key) &&
                existsIn(compare, key) &&
                existsIn(snapshot, key) &&
                isEqual(compare[key], snapshot[key])
            ) {
                // Case: Deleted in main, unchanged in snapshot
                // Do not add to result
            } else if (existsIn(main, key) && existsIn(compare, key) && !existsIn(snapshot, key)) {
                // Case: Deleted in snapshot, do not add
            } else {
                // Recursively merge nested objects, giving priority to snapshot
                const mergedSubObject = mergeObjects(main?.[key], compare?.[key], snapshot?.[key]);
                if (Object.keys(mergedSubObject).length > 0) {
                    result[key] = mergedSubObject;
                }
            }
        } else if (isArray(main?.[key]) || isArray(compare?.[key]) || isArray(snapshot?.[key])) {
            if (existsIn(main, key) && existsIn(compare, key) && !existsIn(snapshot, key)) {
                // Case: Deleted in snapshot, do not add
            } else if (
                !existsIn(main, key) &&
                existsIn(compare, key) &&
                existsIn(snapshot, key) &&
                isEqual(compare[key], snapshot[key])
            ) {
                // Case: Deleted in main, unchanged in snapshot
                // Do not add to result
            } else if (existsIn(main, key) && !existsIn(compare, key) && !existsIn(snapshot, key)) {
                // Added in main, does not exist in snapshot
                result[key] = main[key];
            } else if (existsIn(main, key) && existsIn(compare, key) && existsIn(snapshot, key)) {
                if (isEqual(compare[key], snapshot[key])) {
                    result[key] = main[key];
                } else {
                    result[key] = snapshot[key]; // Give priority to snapshot
                }
            } else if (!existsIn(main, key) && existsIn(snapshot, key)) {
                result[key] = snapshot[key]; // Use snapshot if main doesn't have it
            } else if (existsIn(main, key) && !existsIn(compare, key) && existsIn(snapshot, key)) {
                result[key] = snapshot[key]; // Give priority to snapshot
            } else {
                // Merge arrays, prioritizing snapshot
                result[key] = [
                    ...new Set([...(snapshot?.[key] || []), ...(main?.[key] || []), ...(compare?.[key] || [])]),
                ];
            }
        } else {
            // Handle non-object and non-array values
            if (existsIn(main, key) && existsIn(compare, key) && !existsIn(snapshot, key)) {
                // Case: Deleted in snapshot, do not add
            } else if (
                !existsIn(main, key) &&
                existsIn(compare, key) &&
                existsIn(snapshot, key) &&
                compare[key] === snapshot[key]
            ) {
                // Case: Deleted in main, unchanged in snapshot
                // Do not add to result
            } else if (existsIn(main, key) && !existsIn(compare, key) && !existsIn(snapshot, key)) {
                result[key] = main[key];
            } else if (existsIn(main, key) && existsIn(compare, key) && existsIn(snapshot, key)) {
                if (compare[key] === snapshot[key]) {
                    result[key] = main[key];
                } else {
                    result[key] = snapshot[key]; // Give priority to snapshot
                }
            } else if (!existsIn(main, key) && existsIn(snapshot, key)) {
                result[key] = snapshot[key]; // Give priority to snapshot
            } else if (existsIn(main, key) && !existsIn(compare, key) && existsIn(snapshot, key)) {
                result[key] = snapshot[key]; // Give priority to snapshot
            }
        }
    });

    return result;
};

export const mergeArrayOfObjects = (mainArray, compareArray, snapshotArray, keyField = "id") => {
    if (!Array.isArray(mainArray) || !Array.isArray(compareArray) || !Array.isArray(snapshotArray)) {
        return [];
    }

    const result = [];

    // Helper function to find an object in an array by key-value pair
    const findByKey = (array, key, value) => array.find((obj) => obj[key] === value);

    // Get a list of all unique IDs
    const allKeys = new Set([
        ...mainArray.map((item) => item[keyField]),
        ...compareArray.map((item) => item[keyField]),
        ...snapshotArray.map((item) => item[keyField]),
    ]);

    allKeys.forEach((keyValue) => {
        const mainObject = findByKey(mainArray, keyField, keyValue);
        const compareObject = findByKey(compareArray, keyField, keyValue);
        const snapshotObject = findByKey(snapshotArray, keyField, keyValue);

        if (mainObject && compareObject && snapshotObject) {
            if (isEqual(compareObject, snapshotObject)) {
                result.push(mainObject); // Keep changes from mainObject
            } else {
                result.push(snapshotObject); // Revert to snapshot if there's a conflict
            }
        } else if (mainObject && !compareObject && !snapshotObject) {
            result.push(mainObject); // Added in mainArray
        } else if (!mainObject && compareObject && snapshotObject && isEqual(compareObject, snapshotObject)) {
            // Deleted case, do nothing
        } else if (mainObject && compareObject && !snapshotObject) {
            // Deleted case, do nothing
        } else if (mainObject && snapshotObject && !compareObject) {
            result.push(snapshotObject);
        } else if (!mainObject && snapshotObject) {
            result.push(snapshotObject); // Re-add from snapshot
        }
    });

    return result;
};

export const findUnequalFields = (obj1, obj2, path = "") => {
    const unequalFields = [];

    // Helper function to build the path
    const buildPath = (key) => (path ? `${path}.${key}` : key);

    // Helper function to check if a value is an object
    const isObject = (value) => value && typeof value === "object" && !Array.isArray(value);

    // Combine keys from both objects to handle all cases
    const keys = new Set([...Object.keys(obj1 || {}), ...Object.keys(obj2 || {})]);

    keys.forEach((key) => {
        const fullPath = buildPath(key);
        const value1 = obj1?.[key];
        const value2 = obj2?.[key];

        if (isObject(value1) && isObject(value2)) {
            // Recursively compare nested objects
            unequalFields.push(...findUnequalFields(value1, value2, fullPath));
        } else if (Array.isArray(value1) && Array.isArray(value2)) {
            // Compare arrays (by value)
            if (!isEqual(value1, value2)) {
                unequalFields.push(fullPath);
            }
        } else if (value1 !== value2) {
            // For non-object and non-array values, check if they are unequal
            unequalFields.push(fullPath);
        }
    });

    return unequalFields;
};
