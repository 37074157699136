import React, { useCallback, useEffect, useState } from "react";
import { MODEL_COLL } from "_firebase";
import globalStyles from "components/globalStyles.module.scss";
import classes from "../style.module.scss";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputFile from "components/Input/FileUpload";
import Checkbox from "components/Input/Checkbox";
import { isEmpty, isEqual } from "lodash";

const TableauNewBuildBatchEcms = ({ ecmInputMap, setEcmInputMap, disabledFields }) => {
    return (
        <div className={classes.ecmListContainer}>
            <InputRow gridTemplate="1fr 1fr">
                {/* Ceilings with Attics */}
                <div className={classes.checkboxContainer}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Ceilings with Attic</h4>
                    <Checkbox
                        name="atticR40"
                        label="R-40 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR40-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR40-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR40-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR40-Opt-AtticCeilings"]}
                    />
                    <Checkbox
                        name="atticR50"
                        label="R-50 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR50-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR50-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR50-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR50-Opt-AtticCeilings"]}
                    />
                    <Checkbox
                        name="atticR60"
                        label="R-60 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR60-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR60-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR60-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR60-Opt-AtticCeilings"]}
                    />
                    <Checkbox
                        name="atticR70"
                        label="R-70 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR70-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR70-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR70-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR70-Opt-AtticCeilings"]}
                    />
                    <Checkbox
                        name="atticR80"
                        label="R-80 Fibreglass Blown-in"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AtticCeilings"]: {
                                        ...prevState["Opt-AtticCeilings"],
                                        ["CeilR80-Opt-AtticCeilings"]:
                                            !prevState["Opt-AtticCeilings"]["CeilR80-Opt-AtticCeilings"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AtticCeilings"]["CeilR80-Opt-AtticCeilings"],
                        }}
                        disabled={disabledFields["CeilR80-Opt-AtticCeilings"]}
                    />
                </div>
                {/* Walls */}
                <div className={classes.checkboxContainer}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Above Grade Walls</h4>
                    <Checkbox
                        name="wallR17"
                        label="R-17 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-17(eff)"]: !prevState["Opt-AboveGradeWall"]["NC_R-17(eff)"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["NC_R-17(eff)"],
                        }}
                        disabled={disabledFields["NC_R-17(eff)"]}
                    />
                    <Checkbox
                        name="wallR21"
                        label="R-21 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-21(eff)"]: !prevState["Opt-AboveGradeWall"]["NC_R-21(eff)"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["NC_R-21(eff)"],
                        }}
                        disabled={disabledFields["NC_R-21(eff)"]}
                    />
                    <Checkbox
                        name="wallR26"
                        label="R-26 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-26(eff)"]: !prevState["Opt-AboveGradeWall"]["NC_R-26(eff)"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["NC_R-26(eff)"],
                        }}
                        disabled={disabledFields["NC_R-26(eff)"]}
                    />
                    <Checkbox
                        name="wallR30"
                        label="R-30 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-30(eff)"]: !prevState["Opt-AboveGradeWall"]["NC_R-30(eff)"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["NC_R-30(eff)"],
                        }}
                        disabled={disabledFields["NC_R-30(eff)"]}
                    />
                    <Checkbox
                        name="wallR40"
                        label="R-40 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-AboveGradeWall"]: {
                                        ...prevState["Opt-AboveGradeWall"],
                                        ["NC_R-40(eff)"]: !prevState["Opt-AboveGradeWall"]["NC_R-40(eff)"],
                                    },
                                })),
                            value: ecmInputMap["Opt-AboveGradeWall"]["NC_R-40(eff)"],
                        }}
                        disabled={disabledFields["NC_R-40(eff)"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* Airtightness */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Airtightness</h5>
                    <Checkbox
                        name="ach35"
                        label="3.5 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-3.5"]: !prevState["Opt-ACH"]["airSealTo-3.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-3.5"],
                        }}
                        disabled={disabledFields["airSealTo-3.5"]}
                    />
                    <Checkbox
                        name="ach30"
                        label="3.0 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-3.0"]: !prevState["Opt-ACH"]["airSealTo-3.0"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-3.0"],
                        }}
                        disabled={disabledFields["airSealTo-3.0"]}
                    />
                    <Checkbox
                        name="ach25"
                        label="2.5 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-2.5"]: !prevState["Opt-ACH"]["airSealTo-2.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-2.5"],
                        }}
                        disabled={disabledFields["airSealTo-2.5"]}
                    />
                    <Checkbox
                        name="ach20"
                        label="2.0 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-2.0"]: !prevState["Opt-ACH"]["airSealTo-2.0"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-2.0"],
                        }}
                        disabled={disabledFields["airSealTo-2.0"]}
                    />
                    <Checkbox
                        name="ach15"
                        label="1.5 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-1.5"]: !prevState["Opt-ACH"]["airSealTo-1.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-1.5"],
                        }}
                        disabled={disabledFields["airSealTo-1.5"]}
                    />
                    <Checkbox
                        name="ach10"
                        label="1.0 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-1.0"]: !prevState["Opt-ACH"]["airSealTo-1.0"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-1.0"],
                        }}
                        disabled={disabledFields["airSealTo-1.0"]}
                    />
                    <Checkbox
                        name="ach06"
                        label="0.6 ACH"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-ACH"]: {
                                        ...prevState["Opt-ACH"],
                                        ["airSealTo-0.6"]: !prevState["Opt-ACH"]["airSealTo-0.6"],
                                    },
                                })),
                            value: ecmInputMap["Opt-ACH"]["airSealTo-0.6"],
                        }}
                        disabled={disabledFields["airSealTo-0.6"]}
                    />
                </div>
                {/* Windows */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Windows</h5>
                    <Checkbox
                        name="windowDG_MG_1-6"
                        label="Double Glazed, Medium Gain, Low-e, Argon, Warm Edge Spacer, Vinyl Frame (U=1.6, SHGC=0.3)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["2g-MG-u1.6"]: !prevState["Opt-Windows"]["2g-MG-u1.6"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["2g-MG-u1.6"],
                        }}
                        disabled={disabledFields["2g-MG-u1.6"]}
                    />
                    <Checkbox
                        name="windowDG_LG_1-6"
                        label="Double Glazed, Low Gain, Low-e, Argon, Warm Edge Spacer, Vinyl Frame (U=1.6, SHGC=0.2)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["2g-LG-u1.6"]: !prevState["Opt-Windows"]["2g-LG-u1.6"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["2g-LG-u1.6"],
                        }}
                        disabled={disabledFields["2g-LG-u1.6"]}
                    />
                    <Checkbox
                        name="windowDG_MG_1-2"
                        label="Double Glazed, Medium Gain, Low-e, Argon, Super Spacer, Vinyl Frame (U=1.2, SHGC=0.3)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["2g-MG-u1.2"]: !prevState["Opt-Windows"]["2g-MG-u1.2"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["2g-MG-u1.2"],
                        }}
                        disabled={disabledFields["2g-MG-u1.2"]}
                    />
                    <Checkbox
                        name="windowDG_LG_1-2"
                        label="Double Glazed, Low Gain, Low-e, Argon, Super Spacer, Vinyl Frame (U=1.2, SHGC=0.2)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["2g-LG-u1.2"]: !prevState["Opt-Windows"]["2g-LG-u1.2"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["2g-LG-u1.2"],
                        }}
                        disabled={disabledFields["2g-LG-u1.2"]}
                    />
                    <Checkbox
                        name="windowTG_MG_1-05"
                        label="Triple Glazed, Medium Gain, Low-e, Argon, Super Spacer, Vinyl Frame (U=1.05, SHGC=0.3)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["3g-MG-u1.05"]: !prevState["Opt-Windows"]["3g-MG-u1.05"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["3g-MG-u1.05"],
                        }}
                        disabled={disabledFields["3g-MG-u1.05"]}
                    />
                    <Checkbox
                        name="windowTG_LG_1-05"
                        label="Triple Glazed, Low Gain, Low-e, Argon, Super Spacer, Vinyl Frame (U=1.05, SHGC=0.2)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["3g-LG-u1.05"]: !prevState["Opt-Windows"]["3g-LG-u1.05"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["3g-LG-u1.05"],
                        }}
                        disabled={disabledFields["3g-LG-u1.05"]}
                    />
                    <Checkbox
                        name="windowTG_MG_0-8"
                        label="Triple Glazed, Medium Gain, Low-e, Argon, Super Spacer, Vinyl Frame (U=0.8, SHGC=0.3)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["3g-MG-u0.8"]: !prevState["Opt-Windows"]["3g-MG-u0.8"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["3g-MG-u0.8"],
                        }}
                        disabled={disabledFields["3g-MG-u0.8"]}
                    />
                    <Checkbox
                        name="windowTG_LG_0-8"
                        label="Triple Glazed, Low Gain, Low-e, Argon, Super Spacer, Vinyl Frame (U=0.8, SHGC=0.2)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Windows"]: {
                                        ...prevState["Opt-Windows"],
                                        ["3g-LG-u0.8"]: !prevState["Opt-Windows"]["3g-LG-u0.8"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Windows"]["3g-LG-u0.8"],
                        }}
                        disabled={disabledFields["3g-LG-u0.8"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* Foundation Wall (INT) */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Foundation Wall</h5>
                    <Checkbox
                        name="FoundationWallEffR15"
                        label="R-15 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallIntIns"]: {
                                        ...prevState["Opt-FoundationWallIntIns"],
                                        ["FoundationWallEffR15"]:
                                            !prevState["Opt-FoundationWallIntIns"]["FoundationWallEffR15"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallIntIns"]["FoundationWallEffR15"],
                        }}
                        disabled={disabledFields["FoundationWallEffR15"]}
                    />
                    <Checkbox
                        name="FoundationWallEffR21"
                        label="R-21 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallIntIns"]: {
                                        ...prevState["Opt-FoundationWallIntIns"],
                                        ["FoundationWallEffR21"]:
                                            !prevState["Opt-FoundationWallIntIns"]["FoundationWallEffR21"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallIntIns"]["FoundationWallEffR21"],
                        }}
                        disabled={disabledFields["FoundationWallEffR21"]}
                    />
                    <Checkbox
                        name="FoundationWallEffR28"
                        label="R-28 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationWallIntIns"]: {
                                        ...prevState["Opt-FoundationWallIntIns"],
                                        ["FoundationWallEffR28"]:
                                            !prevState["Opt-FoundationWallIntIns"]["FoundationWallEffR28"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationWallIntIns"]["FoundationWallEffR28"],
                        }}
                        disabled={disabledFields["FoundationWallEffR28"]}
                    />
                </div>
                {/* Below Grade Slab */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Foundation Slab (Below Grade)</h5>
                    <Checkbox
                        name="bgSlabUninsulated"
                        label="Uninsulated"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabBelowGrade"]: {
                                        ...prevState["Opt-FoundationSlabBelowGrade"],
                                        ["uninsulated-Opt-FoundationSlabBelowGrade"]:
                                            !prevState["Opt-FoundationSlabBelowGrade"][
                                                "uninsulated-Opt-FoundationSlabBelowGrade"
                                            ],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabBelowGrade"][
                                "uninsulated-Opt-FoundationSlabBelowGrade"
                            ],
                        }}
                        disabled={disabledFields["uninsulated-Opt-FoundationSlabBelowGrade"]}
                    />
                    <Checkbox
                        name="bgSlabR10"
                        label="R-10 Effective, Sub-slab Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabBelowGrade"]: {
                                        ...prevState["Opt-FoundationSlabBelowGrade"],
                                        ["slabBelowGradeEffR10"]:
                                            !prevState["Opt-FoundationSlabBelowGrade"]["slabBelowGradeEffR10"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabBelowGrade"]["slabBelowGradeEffR10"],
                        }}
                        disabled={disabledFields["slabBelowGradeEffR10"]}
                    />
                    <Checkbox
                        name="bgSlabR20"
                        label="R-20 Effective, Sub-slab Insulation"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabBelowGrade"]: {
                                        ...prevState["Opt-FoundationSlabBelowGrade"],
                                        ["slabBelowGradeEffR20"]:
                                            !prevState["Opt-FoundationSlabBelowGrade"]["slabBelowGradeEffR20"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabBelowGrade"]["slabBelowGradeEffR20"],
                        }}
                        disabled={disabledFields["slabBelowGradeEffR20"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* On-Grade Slab */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Slab-on-Grade</h5>
                    <Checkbox
                        name="slabOnGradeEffR10"
                        label="R-10 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabOnGrade"]: {
                                        ...prevState["Opt-FoundationSlabOnGrade"],
                                        ["slabOnGradeEffR10"]:
                                            !prevState["Opt-FoundationSlabOnGrade"]["slabOnGradeEffR10"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabOnGrade"]["slabOnGradeEffR10"],
                        }}
                        disabled={disabledFields["slabOnGradeEffR10"]}
                    />
                    <Checkbox
                        name="slabOnGradeEffR20"
                        label="R-20 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabOnGrade"]: {
                                        ...prevState["Opt-FoundationSlabOnGrade"],
                                        ["slabOnGradeEffR20"]:
                                            !prevState["Opt-FoundationSlabOnGrade"]["slabOnGradeEffR20"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabOnGrade"]["slabOnGradeEffR20"],
                        }}
                        disabled={disabledFields["slabOnGradeEffR20"]}
                    />
                    <Checkbox
                        name="slabOnGradeEffR30"
                        label="R-30 Effective"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-FoundationSlabOnGrade"]: {
                                        ...prevState["Opt-FoundationSlabOnGrade"],
                                        ["slabOnGradeEffR30"]:
                                            !prevState["Opt-FoundationSlabOnGrade"]["slabOnGradeEffR30"],
                                    },
                                })),
                            value: ecmInputMap["Opt-FoundationSlabOnGrade"]["slabOnGradeEffR30"],
                        }}
                        disabled={disabledFields["slabOnGradeEffR30"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* Heating and Cooling */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Heating and Cooling Systems</h5>
                    <Checkbox
                        name="condGasFurnace95effAC"
                        label="Condensing Natural Gas Furnace (96%) and AC"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["condGasFurnace95eff-AC"]:
                                            !prevState["Opt-Heating-Cooling"]["condGasFurnace95eff-AC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["condGasFurnace95eff-AC"],
                        }}
                        disabled={disabledFields["condGasFurnace95eff-AC"]}
                    />
                    <Checkbox
                        name="hybridHeatCondFurnace96effASHP25COP"
                        label="Hybrid Heating System: ASHP and Natural Gas Furnace (96%)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["hybridHeatCondFurnace96eff-ASHP2.5COP"]:
                                            !prevState["Opt-Heating-Cooling"]["hybridHeatCondFurnace96eff-ASHP2.5COP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["hybridHeatCondFurnace96eff-ASHP2.5COP"],
                        }}
                        disabled={disabledFields["hybridHeatCondFurnace96eff-ASHP2.5COP"]}
                    />
                    <Checkbox
                        name="elecBaseboardAC"
                        label="Electric Resistance Baseboards and AC"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["elec-baseboard+AC"]: !prevState["Opt-Heating-Cooling"]["elec-baseboard+AC"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["elec-baseboard+AC"],
                        }}
                        disabled={disabledFields["elec-baseboard+AC"]}
                    />
                    <Checkbox
                        name="elecHeatingASHP25COP"
                        label="Air Source Heat Pump"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["elecHeating-ASHP2.5COP"]:
                                            !prevState["Opt-Heating-Cooling"]["elecHeating-ASHP2.5COP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["elecHeating-ASHP2.5COP"],
                        }}
                        disabled={disabledFields["elecHeating-ASHP2.5COP"]}
                    />
                    <Checkbox
                        name="elecHeatingCCASHP38COP"
                        label="Cold Climate Air Source Heat Pump"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["elecHeating-CCASHP3.8COP"]:
                                            !prevState["Opt-Heating-Cooling"]["elecHeating-CCASHP3.8COP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["elecHeating-CCASHP3.8COP"],
                        }}
                        disabled={disabledFields["elecHeating-CCASHP3.8COP"]}
                    />
                    <Checkbox
                        name="elecHeatingGSHP"
                        label="Ground Source Heat Pump"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["elecHeating-GSHP"]: !prevState["Opt-Heating-Cooling"]["elecHeating-GSHP"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["elecHeating-GSHP"],
                        }}
                        disabled={disabledFields["elecHeating-GSHP"]}
                    />
                    <Checkbox
                        name="combo08EFTank"
                        label="Combo System (non-P9 rated): 0.8 EF Tank with Air Handler (incl. DHW)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["combo0.8EFTank"]: !prevState["Opt-Heating-Cooling"]["combo0.8EFTank"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["combo0.8EFTank"],
                        }}
                        disabled={disabledFields["combo0.8EFTank"]}
                    />
                    <Checkbox
                        name="p9Combo09TPF"
                        label="Combo System (P9 rated): 0.9 Thermal Performance Factor (incl. DHW system)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["p9Combo0.9TPF"]: !prevState["Opt-Heating-Cooling"]["p9Combo0.9TPF"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["p9Combo0.9TPF"],
                        }}
                        disabled={disabledFields["p9Combo0.9TPF"]}
                    />
                    <Checkbox
                        name="combo095EFTankless"
                        label="Combo System (non-P9 rated): 0.95 EF Tankless with Air Handler (incl. DHW system)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["combo0.95EFTankless"]:
                                            !prevState["Opt-Heating-Cooling"]["combo0.95EFTankless"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["combo0.95EFTankless"],
                        }}
                        disabled={disabledFields["combo0.95EFTankless"]}
                    />
                    <Checkbox
                        name="p9Combo096TPF"
                        label="Combo System (P9 rated): 0.96 Thermal Performance Factor (incl. DHW system)"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-Heating-Cooling"]: {
                                        ...prevState["Opt-Heating-Cooling"],
                                        ["p9Combo0.96TPF"]: !prevState["Opt-Heating-Cooling"]["p9Combo0.96TPF"],
                                    },
                                })),
                            value: ecmInputMap["Opt-Heating-Cooling"]["p9Combo0.96TPF"],
                        }}
                        disabled={disabledFields["p9Combo0.96TPF"]}
                    />
                </div>
                {/* Hot Water */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Domestic Hot Water System</h5>
                    <Checkbox
                        name="dhwGasStorageEF067"
                        label="0.67 EF Natural Gas Powervent Tank"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwGasStorage-EF0.67"]: !prevState["Opt-DHWSystem"]["dhwGasStorage-EF0.67"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwGasStorage-EF0.67"],
                        }}
                        disabled={disabledFields["dhwGasStorage-EF0.67"]}
                    />
                    <Checkbox
                        name="dhwGasStorageEF080"
                        label="0.8 EF Natural Gas Condensing Tank"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwGasStorage-EF0.80"]: !prevState["Opt-DHWSystem"]["dhwGasStorage-EF0.80"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwGasStorage-EF0.80"],
                        }}
                        disabled={disabledFields["dhwGasStorage-EF0.80"]}
                    />
                    <Checkbox
                        name="dhwGasInstEF095"
                        label="0.95 EF Natural Gas Tankless"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwGasInst-EF0.95"]: !prevState["Opt-DHWSystem"]["dhwGasInst-EF0.95"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwGasInst-EF0.95"],
                        }}
                        disabled={disabledFields["dhwGasInst-EF0.95"]}
                    />
                    <Checkbox
                        name="dhwElecStorageEF092"
                        label="0.92 EF Electric Tank"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwElecStorage-EF0.92"]: !prevState["Opt-DHWSystem"]["dhwElecStorage-EF0.92"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwElecStorage-EF0.92"],
                        }}
                        disabled={disabledFields["dhwElecStorage-EF0.92"]}
                    />
                    <Checkbox
                        name="dhwHPWHEF35"
                        label="3.5 EF Heat Pump Water Heater"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DHWSystem"]: {
                                        ...prevState["Opt-DHWSystem"],
                                        ["dhwHPWH-EF3.5"]: !prevState["Opt-DHWSystem"]["dhwHPWH-EF3.5"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DHWSystem"]["dhwHPWH-EF3.5"],
                        }}
                        disabled={disabledFields["dhwHPWH-EF3.5"]}
                    />
                </div>
            </InputRow>
            <InputRow gridTemplate="1fr 1fr">
                {/* HRV */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Heat Recovery Ventilator</h5>
                    <Checkbox
                        name="hrvSRE60"
                        label="60% Efficient HRV"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-VentSystem"]: {
                                        ...prevState["Opt-VentSystem"],
                                        ["hrv-SRE60"]: !prevState["Opt-VentSystem"]["hrv-SRE60"],
                                    },
                                })),
                            value: ecmInputMap["Opt-VentSystem"]["hrv-SRE60"],
                        }}
                        disabled={disabledFields["hrv-SRE60"]}
                    />
                    <Checkbox
                        name="hrvSRE75"
                        label="75% Efficient HRV"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-VentSystem"]: {
                                        ...prevState["Opt-VentSystem"],
                                        ["hrv-SRE75"]: !prevState["Opt-VentSystem"]["hrv-SRE75"],
                                    },
                                })),
                            value: ecmInputMap["Opt-VentSystem"]["hrv-SRE75"],
                        }}
                        disabled={disabledFields["hrv-SRE75"]}
                    />
                </div>
                {/* DWHR */}
                <div className={classes.checkboxContainer}>
                    <h5 style={{ marginBottom: "0.5rem" }}>Drain Water Heat Recovery</h5>
                    <Checkbox
                        name="noDwhr"
                        label="None"
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DWHR"]: {
                                        ...prevState["Opt-DWHR"],
                                        ["None;Mec_4a_X"]: !prevState["Opt-DWHR"]["None;Mec_4a_X"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DWHR"]["None;Mec_4a_X"],
                        }}
                        disabled={disabledFields["None;Mec_4a_X"]}
                    />
                    <Checkbox
                        name="dwhr42eff"
                        label="DWHR unit, 42% Eff."
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DWHR"]: {
                                        ...prevState["Opt-DWHR"],
                                        ["dwhr-42eff"]: !prevState["Opt-DWHR"]["dwhr-42eff"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DWHR"]["dwhr-42eff"],
                        }}
                        disabled={disabledFields["dwhr-42eff"]}
                    />
                    <Checkbox
                        name="dwhr55"
                        label="DWHR unit, 55% Eff."
                        input={{
                            onChange: () =>
                                setEcmInputMap((prevState) => ({
                                    ...prevState,
                                    ["Opt-DWHR"]: {
                                        ...prevState["Opt-DWHR"],
                                        ["dwhr-55eff"]: !prevState["Opt-DWHR"]["dwhr-55eff"],
                                    },
                                })),
                            value: ecmInputMap["Opt-DWHR"]["dwhr-55eff"],
                        }}
                        disabled={disabledFields["dwhr-55eff"]}
                    />
                </div>
            </InputRow>
            <div className={classes.textWrapper}>
                <p>All remaining categories only contain one ECM option and may not be toggled off.</p>
            </div>
        </div>
    );
};
export default TableauNewBuildBatchEcms;
